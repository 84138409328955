import request from '@/utils/request'

export function apiUpload(data) {
  return request({
    url: '/upload_image',
    method: 'post',
    data,
  })
}

export function apiDelete(data) {
  return request({
    url: '/web/dataset/call',
    method: 'post',
    data,
  })
}
