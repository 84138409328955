<template>
  <div class="btn-toolbar quick-editor" :style="style_obj">
    <vs-button-group v-if="editing" style="display: flex; flex-direction: row; flex-wrap: wrap;">
      <vs-tooltip :bottom="tooltip_bottom">
        <vs-button transparent @click="isPopupStyle = true">
          <i class="fas fa-magic"></i>
        </vs-button>
        <template #tooltip>
          Style
        </template>
      </vs-tooltip>
      <vs-tooltip :bottom="tooltip_bottom">
        <vs-button
            v-show="editing_text"
            @click="editing.on_bold()"
            transparent
            :active="bold"
        >
          <i class="fas fa-bold"></i
          >
        </vs-button>
        <template #tooltip>
          Đậm
        </template>
      </vs-tooltip>
      <vs-tooltip :bottom="tooltip_bottom">
        <vs-button
            v-show="editing_text"
            @click="editing.on_italic()"
            transparent
            :active="italic"
        >
          <i class="fas fa-italic"/>
        </vs-button>
        <template #tooltip>
          Nghiêng
        </template>
      </vs-tooltip>
      <vs-tooltip :bottom="tooltip_bottom">
        <vs-button
            v-show="editing_text"
            @click="editing.on_strike()"
            transparent
            :active="strike"
        >
          <i class="fas fa-strikethrough"></i>
        </vs-button>
        <template #tooltip>
          Gạch
        </template>
      </vs-tooltip>
      <vs-tooltip :bottom="tooltip_bottom">
        <vs-button
            v-show="editing_text"
            @click="editing.on_align_left()"
            transparent
            :active="editing.left"
        >
          <i class="fas fa-align-left"/>
        </vs-button>
        <template #tooltip>
          Căn trái
        </template>
      </vs-tooltip>
      <vs-tooltip :bottom="tooltip_bottom">
        <vs-button
            v-show="editing_text"
            @click="editing.on_align_center()"
            transparent
            :active="editing.center"
        >
          <i class="fas fa-align-center"/>
        </vs-button>
        <template #tooltip>
          Căn giữa
        </template>
      </vs-tooltip>
      <vs-tooltip :bottom="tooltip_bottom">
        <vs-button
            v-show="editing_text"
            @click="editing.on_align_right()"
            transparent
            :active="editing.right"
        >
          <i class="fas fa-align-right"/>
        </vs-button>
        <template #tooltip>
          Căn phải
        </template>
      </vs-tooltip>
      <vs-tooltip :bottom="tooltip_bottom">
        <vs-button
            v-show="editing_text"
            @click="editing.on_align_justify()"
            transparent
            :active="editing.justify"
        >
          <i class="fas fa-align-justify"/>
        </vs-button>
        <template #tooltip>
          Căn đều
        </template>
      </vs-tooltip>
      <vs-tooltip :bottom="tooltip_bottom">
        <vs-button
            v-show="editing_text"
            transparent
            class="btn-font-select"
        >
          <i class="fas fa-font"/>
          <div class="dropdown-menu m-0">
            <a
                class="dropdown-item font-item"
                style="font-family: Arial, Helvetica, sans-serif"
                @click="editing.change_font('Arial, Helvetica, sans-serif')"
            >Arial</a
            >
            <a
                class="dropdown-item font-item"
                style="font-family: Arial Black, sans-serif"
                @click="editing.change_font('Arial Black, sans-serif')"
            >Arial Black</a
            >
            <a
                class="dropdown-item font-item"
                style="font-family: Bradley Hand, cursive"
                @click="editing.change_font('Bradley Hand, cursive')"
            >Bradley Hand</a
            >
            <a
                class="dropdown-item font-item"
                style="font-family: Comic Sans MS, cursive, sans-serif"
                @click="editing.change_font('Comic Sans MS, cursive, sans-serif')"
            >Comic Sans</a
            >
            <a
                class="dropdown-item font-item"
                style="font-family: Courier, monospace"
                @click="editing.change_font('Courier, monospace')"
            >Courier</a
            >
            <a
                class="dropdown-item font-item"
                style="font-family: Helvetica, sans-serif"
                @click="editing.change_font('Helvetica, sans-serif')"
            >Helvetica</a
            >
            <a
                class="dropdown-item font-item"
                style="font-family: Lucida Console, monospace"
                @click="editing.change_font('Lucida Console, monospace')"
            >Lucida Console</a
            >
            <a
                class="dropdown-item font-item"
                style="font-family: Montserrat, sans-serif"
                @click="editing.change_font('Montserrat, sans-serif')"
            >Montserrat</a
            >
            <a
                class="dropdown-item font-item"
                style="font-family: Quicksand, sans-serif"
                @click="editing.change_font('Quicksand, sans-serif')"
            >Quicksand</a
            >
            <a
                class="dropdown-item font-item"
                style="font-family: Times New Roman, serif"
                @click="editing.change_font('Times New Roman, serif')"
            >Times New Roman</a
            >
            <a
                class="dropdown-item font-item"
                style="font-family: Verdana, sans-serif"
                @click="editing.change_font('Verdana, sans-serif')"
            >Verdana</a
            >
          </div>
        </vs-button>
        <template #tooltip>
          Font chữ
        </template>
      </vs-tooltip>
      <vs-tooltip :bottom="tooltip_bottom">
        <vs-button
            v-show="editing_text"
            transparent
            class="btn-font-select"
        >
          <i class="fas fa-text-height"></i>
          <div class="dropdown-menu m-0">
            <a
                class="dropdown-item font-item"
                @click="editing.change_font_size(8)"
            >8pt</a
            >
            <a
                class="dropdown-item font-item"
                @click="editing.change_font_size(9)"
            >9pt</a
            >
            <a
                class="dropdown-item font-item"
                @click="editing.change_font_size(10)"
            >10pt</a
            >
            <a
                class="dropdown-item font-item"
                @click="editing.change_font_size(11)"
            >11pt</a
            >
            <a
                class="dropdown-item font-item"
                @click="editing.change_font_size(12)"
            >12pt</a
            >
            <a
                class="dropdown-item font-item"
                @click="editing.change_font_size(14)"
            >14pt</a
            >
            <a
                class="dropdown-item font-item"
                @click="editing.change_font_size(16)"
            >16pt</a
            >
            <a
                class="dropdown-item font-item"
                @click="editing.change_font_size(20)"
            >20pt</a
            >
            <a
                class="dropdown-item font-item"
                @click="editing.change_font_size(24)"
            >24pt</a
            >
            <a
                class="dropdown-item font-item"
                @click="editing.change_font_size(28)"
            >28pt</a
            >
            <a
                class="dropdown-item font-item"
                @click="editing.change_font_size(32)"
            >32pt</a
            >
            <a
                class="dropdown-item font-item"
                @click="editing.change_font_size(36)"
            >36pt</a
            >
            <a
                class="dropdown-item font-item"
                @click="editing.change_font_size(40)"
            >40pt</a
            >
            <a
                class="dropdown-item font-item"
                @click="editing.change_font_size(50)"
            >50pt</a
            >
            <a
                class="dropdown-item font-item"
                @click="editing.change_font_size(60)"
            >60pt</a
            >
            <a
                class="dropdown-item font-item"
                @click="editing.change_font_size(72)"
            >72pt</a
            >
          </div>
        </vs-button>
        <template #tooltip>
          Cỡ chữ
        </template>
      </vs-tooltip>
      <vs-tooltip :bottom="tooltip_bottom">
        <vs-button
            v-show="editing_text"
            transparent
            class="btn-font-select"
        >
          <i class="fas fa-palette"/>
          <div class="dropdown-menu m-0">
            <ColourPicker
                class="color-pick"
                v-model="color"
                :color="color"
                label="Chọn màu chữ"
                picker="compact"
            />
          </div>
        </vs-button>
        <template #tooltip>
          Màu chữ
        </template>
      </vs-tooltip>
      <vs-tooltip :bottom="tooltip_bottom">
        <vs-button
            v-if="is_image && !editing_text"
            @click="isDialogUpload = !isDialogUpload"
            transparent
            :active="bold"
        ><i class="fas fa-image"></i
        ></vs-button>
        <template #tooltip>
          Ảnh nền
        </template>
      </vs-tooltip>
      <vs-tooltip :bottom="tooltip_bottom">
        <vs-button
            @click="editing.clone()"
            v-show="!editing_text"
            transparent
        ><i class="fas fa-clone"></i
        ></vs-button>
        <template #tooltip>
          Nhân bản
        </template>
      </vs-tooltip>
      <vs-tooltip :bottom="tooltip_bottom">
        <vs-button
            @click="editing.remove()"
            v-show="!editing_text"
            transparent
        ><i class="fas fa-trash"></i
        ></vs-button>
        <template #tooltip>
          Xoá
        </template>
      </vs-tooltip>
      <vs-tooltip :bottom="tooltip_bottom">
        <vs-button
            @click="toggle_edit_text"
            v-show="(is_text && !editing_text) || is_html_content"
            transparent
        >
          <i class="fas fa-pen-alt"></i>
        </vs-button>
        <template #tooltip>
          Sửa nội dung (Ctrl + E)
        </template>
      </vs-tooltip>
      <vs-tooltip :bottom="tooltip_bottom">
        <vs-button
            @click="toggle_edit_text"
            v-show="editing_text"
            transparent
            color="#008000"
        >
          <i class="fas fa-check"></i>
        </vs-button>
        <template #tooltip>
          Hoàn thành chỉnh sửa
        </template>
      </vs-tooltip>
      <vs-tooltip :bottom="tooltip_bottom">
        <vs-button
            @click="reset_position"
            v-show="!editing_text"
            transparent
        >
          <i class="fas fa-crosshairs"></i>
        </vs-button>
        <template #tooltip>
          Reset vị trí
        </template>
      </vs-tooltip>

      <vs-tooltip :bottom="tooltip_bottom">
        <vs-button
            transparent
            v-show="editing && editing.isNav"
            @click="editing.add()"
        >
          <i class="fas fa-plus"/>
        </vs-button>
        <template #tooltip>
          Thêm tab
        </template>
      </vs-tooltip>
      <vs-tooltip :bottom="tooltip_bottom">
        <vs-button
            transparent
            v-show="editing && editing.isNav"
            @click="editing.remove()"
        >
          <i class="fas fa-minus"/>
        </vs-button>
        <template #tooltip>
          Xoá tab cuối
        </template>
      </vs-tooltip>


      <vs-tooltip :bottom="tooltip_bottom">
        <vs-button
            @click="$store.state.editing_html_content = true, editing.component_data.innerHTML = $store.state.blog_detail.content"
            v-show="is_blogl_detail_content"
            transparent
        >
          <i class="fas fa-pen-alt"></i>
        </vs-button>
        <template #tooltip>
          Sửa nội dung
        </template>
      </vs-tooltip>

      <vs-tooltip :bottom="tooltip_bottom">
        <vs-button
            @click="editSelect2 = true"
            v-show="editing && editing.isSelect2"
            transparent
        >
          <i class="fas fa-pen-alt"></i>
        </vs-button>
        <template #tooltip>
          Sửa nội dung
        </template>
      </vs-tooltip>

      <vs-tooltip :bottom="tooltip_bottom">
        <vs-button
            transparent
            v-show="!editing_text"
            @click="setting()"
        >
          <i class="fas fa-cog"/>
        </vs-button>
        <template #tooltip>
          Cài đặt
        </template>
      </vs-tooltip>
    </vs-button-group>

    <!--Setting select2-->
    <vs-dialog
        not-center
        :loading="loading"
        width="600px"
        class="menu-dialog"
        overflow-hidden
        prevent-close
        v-model="editSelect2"
    >
      <template #header>
        <h4 class="text-center">
          Selection
        </h4>
      </template>
      <div class="container mb-2">
        <div class="row">
          <div class="col-6">
            Text hiển thị
          </div>
          <div class="col-6">
            Gía trị
          </div>
        </div>
      </div>
      <TreeNotDraggable v-if="editing && editing.data" :value="editing.data" class="menu-tree">
        <div slot-scope="{node,path}" class="bg-none container">
          <div class="row">
            <div class="col-6 p-1">
              <vs-input :value="node.text" @blur="(e) => editing.save(e,'text', path[0])"/>
            </div>
            <div class="col-6 p-1">
              <vs-input :value="node.id" @blur="(e) => editing.save(e,'id', path[0])"/>
            </div>
          </div>
        </div>
      </TreeNotDraggable>
      <template #footer>
        <div class="footer-dialog mt-3 d-flex">
          <vs-button class="px-3" circle gradient @click="editing.addItem()">
            <i class="fas fa-plus mr-2 text-white"></i> Thêm item
          </vs-button>
          <vs-button v-if="editing && editing.data && editing.data.length > 1" circle warn gradient class="px-3"
                     @click="editing.removeItem()">
            <i class="fas fa-minus mr-2 text-white"></i> Xoá item cuối
          </vs-button>
        </div>
      </template>
    </vs-dialog>

    <!-- Upload Dialog -->
    <vs-dialog
        not-center
        scroll
        overflow-hidden
        class="upload-dialog"
        prevent-close
        v-model="isDialogUpload"
    >
      <template #header>
        <h4 class="not-margin">
          Thư viện ảnh
        </h4>
      </template>

      <div class="con-form">
        <div class="pb-3">
          <vs-input
              primary
              v-model="urlImage"
              placeholder="Nhập link vào đây"
          >
            <template #icon>
              <i class="fas fa-link"></i>
            </template>
          </vs-input>
        </div>
        <div v-if="urlImage" class="w-50 m-auto border text-center"
             style="background-image: url(https://p1.hiclipart.com/preview/140/298/348/a-whole-lotta-nothing-png-clipart-thumbnail.jpg);">
          <img ref="preview" :src="urlImage" id="preview" class="w-100" alt="" srcset=""
               style="max-height: 200px;object-fit: contain;">
          <div class="bg-white py-1">
            <small>preview: ({{ preview.width }} x {{ preview.height }} px)</small>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-3" style="flex-wrap: wrap;">
          <div
              @click="urlImage = ''"
              class="align-items-center d-flex position-relative border mr-3 justify-content-center"
              style="width: 10rem; height: 10rem;cursor: pointer;"
          >
            <i v-if="!background_image && !imageUrl" class="far fa-check-circle"
               style="position: absolute;right: .5rem;bottom: .5rem;"></i>
            <h5>Trắng</h5>
          </div>
          <div v-for="(item, index) in images" :key="index" class="position-relative mr-3 mb-3">
            <vs-button
                circle
                icon
                floating
                color="primary"
                v-if="background_image === item.url || imageUrl === item.url"
                :active="false"
                style="position: absolute;right: .2rem;bottom: .2rem;"
            >
              <i class="far fa-check-circle text-white"></i>
            </vs-button>
            <vs-button
                circle
                icon
                floating
                color="danger"
                style="position: absolute;right: .2rem;top: .2rem;"
                class="btn-delete"
                @click="deleteImage(item, index, $event)"
            >
              <i class="far fa-trash-alt text-white"></i>
            </vs-button>
            <div
                @click="urlImage = (!item.url.includes('https://') && !item.url.includes('http://')) ? origin + item.url : item.url"
                class="align-items-center d-flex image position-relative border justify-content-center"
                style="width: 10rem; height: 10rem;cursor: pointer;background-color: #f7f7f7;"
            >
              <img class="mw-100 mh-100" :src="item.url" alt="" srcset=""/>
            </div>
          </div>

          <!-- For test -->
          <!-- <div class="position-relative mr-3 mb-3">
            <vs-button
                circle
                icon
                floating
                color="danger"
                style="position: absolute;right: .2rem;top: .2rem;"
                class="btn-delete"
                @click="deleteImage(item, index, $event)"
            >
              <i class="far fa-trash-alt text-white"></i>
            </vs-button>
            <div
              @click="urlImage = 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png'"
              class="align-items-center d-flex image position-relative border justify-content-center"
              style="width: 10rem; height: 10rem;cursor: pointer;background-color: #f7f7f7;"
            >
              <img class="mw-100 mh-100" src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png" alt="" srcset=""/>
            </div>
          </div>

          <div class="position-relative mr-3 mb-3">
            <vs-button
                circle
                icon
                floating
                color="danger"
                style="position: absolute;right: .2rem;top: .2rem;"
                class="btn-delete"
                @click="deleteImage(item, index, $event)"
            >
              <i class="far fa-trash-alt text-white"></i>
            </vs-button>
            <div
              @click="urlImage = 'https://cdn-store.deepsel.com/fpt/4c/4c737407502078631c1bfdfff14202c7d804fd19'"
              class="align-items-center d-flex image position-relative border justify-content-center"
              style="width: 10rem; height: 10rem;cursor: pointer;background-color: #f7f7f7;"
            >
              <img class="mw-100 mh-100" src="https://cdn-store.deepsel.com/fpt/4c/4c737407502078631c1bfdfff14202c7d804fd19" alt="" srcset=""/>
            </div>
          </div> -->

        </div>
      </div>

      <template #footer>
        <div class="con-footer d-flex justify-content-end">
          <vs-button class="px-2" circle flat icon size="mini" @click="$refs.file.click()">
            <i class="fas fa-upload mr-2"></i> Tải lên
          </vs-button>
          <input
              type="file"
              ref="file"
              class="d-none"
              @change="handleFileUpload"
              multiple
          />
          <vs-button class="px-2" circle flat icon success size="mini"
                     @click="isDialogUpload = false, editing.$options.name === 'BaseDetailBlogComponent' ? editing.save({cover_img: urlImage}) : ''">
            <i class="fas fa-check mr-2"></i> Ok
          </vs-button>
        </div>
      </template>
    </vs-dialog>

    <!--Setting Dialog-->
    <vs-dialog
        ref="settings"
        not-center
        scroll
        width="550px"
        overflow-hidden
        class="setting-dialog"
        prevent-close
        v-model="openedSettings"
    >
      <template #header>
        <div class="wrapper" v-if="editing">
          <nav class="tabs">
            <EditorTabs @mounted="tabs_mounted"/>
            <div class="selector" :style="tab_selector_style"></div>
            <a
                class="single-tab"
                ref="video_tab"
                id="video_tab"
                v-show="editing.isVideo"
                :class="{active: active_tab === 'video'}"
                @click="active_tab = 'video'"
            >
              <i class="fas fa-video"></i>Video
            </a>
            <a
                class="single-tab"
                ref="embed_tab"
                id="embed_tab"
                v-show="editing.$options.name === 'BaseEmbedComponent'"
                :class="{active: active_tab === 'embed'}"
                @click="active_tab = 'embed'"
            >
              <i class="far fa-file-code"></i>Embed
            </a>
            <a
                class="single-tab"
                ref="carousel_tab"
                id="carousel_tab"
                v-show="editing.isCarousel"
                :class="{active: active_tab === 'carousel'}"
                @click="active_tab = 'carousel'"
            >
              <i class="fas fa-images"></i>SLIDER
            </a>
            <a
                class="single-tab"
                ref="data_tab"
                id="data_tab"
                v-show="(deepselEsc !== undefined && editing.is_text) || form_data || editing.$options.name === 'BaseMultiProductComponent' || editing.$options.name === 'BaseMultiBlogComponent'"
                :class="{active: active_tab === 'data'}"
                @click="active_tab = 'data'"
            >
              <i class="fas fa-database"></i>DỮ LIỆU
            </a>
            <a
                class="single-tab"
                ref="events_tab"
                id="events_tab"
                v-show="events"
                :class="{active: active_tab === 'events'}"
                @click="active_tab = 'events'"
            >
              <i class="fas fa-bolt"></i>SỰ KIỆN
            </a>
          </nav>
        </div>
      </template>

      <div v-if="openedSettings" class="deepsel-component-editor">
        <div class="card" v-if="editing">

          <!-- Video Settings Tab -->
          <div class="card-body p-2" v-if="events" v-show="active_tab === 'video'">
            <div class="mt-1">
              <div class="setting-item">
                <b>Thiết lập video</b>
                <i class="fas fa-caret-down"></i>
              </div>

              <div class="mt-1 d-flex justify-content-between align-items-center">
                <div style="width: 50%;">
                  Đường dẫn video
                </div>

                <div class="w-100">
                  <vs-input
                      class="mt-1"
                      v-model="editing.video_url"
                      type="url"
                      placeholder="Paste link video youtube vào đây..."
                  />
                </div>
              </div>

              <div class="mt-1 d-flex justify-content-between align-items-center">
                <div style="width: 50%;">
                  Ảnh thumbnail
                </div>

                <div class="w-100">
                  <vs-input
                      class="mt-1"
                      v-model="editing.thumbnail"
                      type="url"
                      placeholder="Paste link video youtube vào đây..."
                  />
                </div>
              </div>

              <div class="mt-1 d-flex justify-content-between align-items-center">
                <div style="width: 50%;">
                  Hiển thị controls
                </div>

                <div class="w-100 d-flex">
                  <vs-switch v-model="editing.controls" color="#46c93a"/>
                </div>
              </div>

              <div class="mt-1 d-flex align-items-center">
                <div style="width: 50%;">
                  Tự động chạy
                </div>

                <div class="w-100 d-flex">
                  <vs-switch v-model="editing.autoplay" color="#46c93a"/>
                </div>
              </div>

              <div class="mt-1 d-flex align-items-center" v-if="!editing.autoplay">
                <div style="width: 50%;">
                  Tắt âm thanh
                </div>

                <div class="w-100 d-flex">
                  <vs-switch v-model="editing.mute" color="#46c93a"/>
                </div>
              </div>

            </div>
          </div>
          <!-- End Video Settings Tab -->

          <!-- Carousel Settings Tab -->
          <div class="card-body p-2" v-if="editing && editing.isCarousel" v-show="active_tab === 'carousel'">

            <div class="mt-1" v-if="editing.isProductListsCollection">
              <div class="setting-item" @click="show_carousel_product_section = !show_carousel_product_section">
                <b>Sản phẩm</b>
                <i v-show="show_carousel_product_section" class="fas fa-caret-down"></i>
                <i v-show="!show_carousel_product_section" class="fas fa-caret-right"></i>
              </div>
              <div v-if="show_carousel_product_section" class="mt-1 d-flex align-items-center">
                <div style="width: 50%;">
                  Bộ sưu tập sản phẩm
                </div>
                <vs-select
                    color="#7d33ff"
                    v-model="collection"
                    placeholder="Chọn một bộ sưu tập sản phẩm"
                >
                  <vs-option
                      v-for="i in $store.state.common_data.collections"
                      :label="i.name"
                      :key="i.id"
                      :value="i.id"
                      class="d-flex align-items-center"
                  >
                    {{ i.name }}
                  </vs-option>
                </vs-select>
              </div>
            </div>

            <div class="mt-1" v-if="editing.data_set === 'blog-list' && !is_blog_page">
              <div class="setting-item" @click="show_carousel_blog_section = !show_carousel_blog_section">
                <b>Lọc bài viết</b>
                <i v-show="show_carousel_blog_section" class="fas fa-caret-down"></i>
                <i v-show="!show_carousel_blog_section" class="fas fa-caret-right"></i>
              </div>

              <div v-show="show_carousel_blog_section">

                <div class="mt-1 d-flex align-items-center">
                  <div style="width: 50%;">
                    Thể loại
                  </div>
                  <div class="w-100">
                    <vs-select color="#7d33ff" v-model="blogFilterCategory" placeholder="Chọn Thể loại">
                      <vs-option
                          label="Không chọn"
                          value="0"
                          class="d-flex align-items-center"
                      >
                        Không chọn
                      </vs-option>
                      <vs-option
                          v-for="(value, name) in $store.state.common_data.blog_categories"
                          :label="value"
                          :key="name"
                          :value="name"
                          class="d-flex align-items-center"
                      >
                        {{ value }}
                      </vs-option>
                    </vs-select>
                  </div>
                </div>

                <div class="mt-1 d-flex align-items-center">
                  <div style="width: 50%;">
                    Tag
                  </div>
                  <div class="w-100">
                    <vs-select color="#7d33ff" v-model="blogFiltertag" placeholder="Chọn Tag">
                      <vs-option
                          label="Không chọn"
                          value="0"
                          class="d-flex align-items-center"
                      >
                        Không chọn
                      </vs-option>
                      <vs-option
                          v-for="(value, name) in $store.state.common_data.blog_tags"
                          :label="value"
                          :key="name"
                          :value="name"
                          class="d-flex align-items-center"
                      >
                        {{ value }}
                      </vs-option>
                    </vs-select>
                  </div>
                </div>

                <div class="mt-1 d-flex align-items-center">
                  <div style="width: 50%;">
                    Limit
                  </div>
                  <div class="w-100">
                    <vs-input
                        type="number"
                        max="100"
                        min="1"
                        step="1"
                        placeholder="limit"
                        v-model="blogFilterLimit"
                    >
                    </vs-input>
                  </div>
                </div>

                <div class="mt-1 d-flex justify-content-between align-items-center">
                  <div style="width: 50%;">
                    Highlighted posts
                  </div>

                  <vs-switch v-model="blogHighlighted" color="#46c93a"/>
                </div>

              </div>
            </div>

            <div class="mt-1">
              <div class="setting-item" @click="show_carousel_play_section = !show_carousel_play_section">
                <b>Chạy Slide</b>
                <i v-show="show_carousel_play_section" class="fas fa-caret-down"></i>
                <i v-show="!show_carousel_play_section" class="fas fa-caret-right"></i>
              </div>
              <div v-show="show_carousel_play_section">
                <div class="mt-1 d-flex justify-content-between align-items-center">
                  <div style="width: 50%;">
                    Chạy tự động
                  </div>
                  <vs-switch v-model="autoplay" color="#46c93a"/>
                </div>
                <div v-if="autoplay" class="mt-1 d-flex justify-content-between align-items-center">
                  <div style="width: 50%;">
                    Tốc độ chạy (mili giây)
                  </div>
                  <vs-input
                      type="number"
                      max="12000"
                      step="1000"
                      icon-after
                      placeholder="mili giây"
                      v-model="autoplayTimeout"
                  >
                    <template #icon>
                      ms
                    </template>
                  </vs-input>
                </div>
                <div class="mt-1 d-flex justify-content-between align-items-center">
                  <div style="width: 50%;">
                    Chạy loop
                  </div>
                  <vs-switch v-model="loop" color="#46c93a"/>
                </div>
              </div>
            </div>

            <div class="mt-1">
              <div class="setting-item" @click="show_carousel_items_section = !show_carousel_items_section">
                <b>Số slide hiển thị</b>
                <i v-show="show_carousel_items_section" class="fas fa-caret-down"></i>
                <i v-show="!show_carousel_items_section" class="fas fa-caret-right"></i>
              </div>

              <div v-if="show_carousel_items_section" class="mt-3">
                <div class="mt-3">
                  <vs-alert dark>
                    <small>
                      Hiệu chỉnh số slide hiển thị tuỳ theo chiều rộng của slider, giúp cho slider của bạn trình bày phù
                      hợp hơn với từng loại màn hình mobile, tablet, hay desktop. *
                    </small>
                  </vs-alert>
                </div>

                <div class="d-flex mt-4">
                  <vs-select
                      color="#7d33ff"
                      v-for="(item, name) in optionsCarousel.responsive"
                      :key="name"
                      class="px-2 mt-2"
                      :label="'Chiều rộng > ' + name + 'px'"
                      v-model="item.items"
                  >
                    <vs-option v-for="i in 20" :label="i" :value="i" :key="i">
                      {{ i }}
                    </vs-option>
                  </vs-select>
                </div>
              </div>
            </div>

            <div class="mt-3 d-flex justify-content-center">
              <vs-button v-if="!editing.data_set" circle icon size="mini" class="m-0 mr-2" color="#1a5cff" flat
                         @click="editing.unshiftItem()">
                <i class="fas fa-plus mr-2"></i> Thêm slide đầu
              </vs-button>
              <vs-button v-if="!editing.data_set" circle icon size="mini" class="m-0 mr-2" color="#1a5cff" flat
                         @click="editing.plusItem()">
                <i class="fas fa-plus mr-2"></i> Thêm slide cuối
              </vs-button>
              <vs-button v-if="!editing.data_set" circle warn flat icon size="mini" class="m-0 mr-2"
                         @click="editing.minusItem()">
                <i class="fas fa-minus mr-2"></i> Xoá slide cuối
              </vs-button>
              <vs-button circle success flat icon size="mini" class="m-0 mr-2 px-2" @click="editing.saveSetting()">
                Áp dụng
              </vs-button>
              <vs-button v-if="!editing.data_set" circle color="#1a5cff" flat icon size="mini" class="px-2 mr-2 m-0"
                         @click="editingCarousel()">
                Thiết kế
              </vs-button>

            </div>
          </div>
          <!-- End Carousel Settings Tab-->

          <!-- Data Settings Tab -->
          <div class="card-body p-2" v-show="active_tab === 'data'">

            <!-- Data form  -->
            <div class="mt-1 d-flex justify-content-between align-items-center" v-if="form_data">
              <div style="width: 50%;">
                Kết nối form
              </div>
              <vs-select
                  style="width: 50%;"
                  color="#7d33ff"
                  v-model="form_index"
                  v-if="forms"
              >
                <vs-option label="" value=0>
                  Không chọn
                </vs-option>
                <vs-option
                    v-for="(form, i) in forms"
                    :key=i+1
                    :label=form.name
                    :value=i+1
                >
                  {{ form.name }}
                </vs-option>
              </vs-select>
              <div class="mt-3 d-flex justify-content-center">
                <vs-button circle color="#1a5cff" flat icon size="mini" @click="manage_forms">
                  Quản lý form
                </vs-button>
              </div>
            </div>
            <!-- End Data form  -->

            <!-- Text data mapping  -->
            <div class="mt-1 d-flex justify-content-between align-items-center"
                 v-if="deepselEsc !== undefined && editing.is_text">
              <div style="width: 50%;">
                Ánh xạ dữ liệu
              </div>
              <vs-select color="#7d33ff" v-model="deepselEsc">
                <vs-option label="Không chọn" :value="0">
                  Không chọn
                </vs-option>

                <vs-option-group>
                  <div slot="title">
                    Website
                  </div>
                  <vs-option
                      label="Tiêu đề"
                      value="website.title"
                      class="d-flex align-items-center"
                  >
                    Tiêu đề
                  </vs-option>
                  <vs-option
                      label="Mô tả"
                      value="website.description"
                      class="d-flex align-items-center"
                  >
                    Mô tả
                  </vs-option>
                  <vs-option
                      label="Mô tả"
                      value="website.repay"
                      class="d-flex align-items-center"
                  >
                    Chính sách đổi trả
                  </vs-option>
                  <vs-option
                      label="Điều khoản sử dụng"
                      value="website.terms_of_use"
                      class="d-flex align-items-center"
                  >
                    Điều khoản sử dụng
                  </vs-option>
                  <vs-option
                      label="Chính sách bảo mật"
                      value="website.privacy_policy"
                      class="d-flex align-items-center"
                  >
                    Chính sách bảo mật
                  </vs-option>
                </vs-option-group>

                <vs-option-group>
                  <div slot="title">
                    Công ty
                  </div>
                  <vs-option
                      label="Tên công ty"
                      value="company.name"
                      class="d-flex align-items-center"
                  >
                    Tên công ty
                  </vs-option>
                  <vs-option
                      label="Mô tả công ty"
                      value="company.about"
                      class="d-flex align-items-center"
                  >
                    Mô tả công ty
                  </vs-option>
                  <vs-option
                      label="Email công ty"
                      value="company.email"
                      class="d-flex align-items-center"
                  >
                    Email
                  </vs-option>
                  <vs-option
                      label="Điện thoại"
                      value="company.phone"
                      class="d-flex align-items-center"
                  >
                    Phone
                  </vs-option>
                  <vs-option
                      label="Tỉnh thành"
                      value="company.province"
                      class="d-flex align-items-center"
                  >
                    Tỉnh thành
                  </vs-option>
                  <vs-option
                      label="Địa chỉ (số nhà)"
                      value="company.street"
                      class="d-flex align-items-center"
                  >
                    Địa chỉ (số nhà)
                  </vs-option>
                </vs-option-group>

              </vs-select>
            </div>
            <!-- End Text data mapping  -->

            <!-- Blog and product data mapping  -->
            <div class="mt-1" v-if="editing.isProductListsCollection">
              <div class="setting-item" @click="show_carousel_product_section = !show_carousel_product_section">
                <b>Sản phẩm</b>
                <i v-show="show_carousel_product_section" class="fas fa-caret-down"></i>
                <i v-show="!show_carousel_product_section" class="fas fa-caret-right"></i>
              </div>
              <div v-if="show_carousel_product_section" class="mt-1 d-flex align-items-center">
                <div style="width: 50%;">
                  Bộ sưu tập sản phẩm
                </div>
                <vs-select
                    color="#7d33ff"
                    v-model="collection"
                    placeholder="Chọn một bộ sưu tập sản phẩm"
                >
                  <vs-option
                      v-for="i in $store.state.common_data.collections"
                      :label="i.name"
                      :key="i.id"
                      :value="i.id"
                      class="d-flex align-items-center"
                  >
                    {{ i.name }}
                  </vs-option>
                </vs-select>
              </div>
            </div>

            <div class="mt-1" v-if="editing.$options.name === 'BaseMultiBlogComponent' && !is_blog_page">
              <div class="setting-item" @click="show_carousel_blog_section = !show_carousel_blog_section">
                <b>Lọc bài viết</b>
                <i v-show="show_carousel_blog_section" class="fas fa-caret-down"></i>
                <i v-show="!show_carousel_blog_section" class="fas fa-caret-right"></i>
              </div>

              <div v-show="show_carousel_blog_section">

                <div class="mt-1 d-flex align-items-center">
                  <div style="width: 50%;">
                    Thể loại
                  </div>
                  <div class="w-100">
                    <vs-select color="#7d33ff" v-model="blogFilterCategory" placeholder="Chọn Thể loại">
                      <vs-option
                          label="Không chọn"
                          value="0"
                          class="d-flex align-items-center"
                      >
                        Không chọn
                      </vs-option>
                      <vs-option
                          v-for="(value, name) in $store.state.common_data.blog_categories"
                          :label="value"
                          :key="name"
                          :value="name"
                          class="d-flex align-items-center"
                      >
                        {{ value }}
                      </vs-option>
                    </vs-select>
                  </div>
                </div>

                <div class="mt-1 d-flex align-items-center">
                  <div style="width: 50%;">
                    Tag
                  </div>
                  <div class="w-100">
                    <vs-select color="#7d33ff" v-model="blogFiltertag" placeholder="Chọn Tag">
                      <vs-option
                          label="Không chọn"
                          value="0"
                          class="d-flex align-items-center"
                      >
                        Không chọn
                      </vs-option>
                      <vs-option
                          v-for="(value, name) in $store.state.common_data.blog_tags"
                          :label="value"
                          :key="name"
                          :value="name"
                          class="d-flex align-items-center"
                      >
                        {{ value }}
                      </vs-option>
                    </vs-select>
                  </div>
                </div>

                <div class="mt-1 d-flex align-items-center">
                  <div style="width: 50%;">
                    Limit
                  </div>
                  <div class="w-100">
                    <vs-input
                        type="number"
                        max="100"
                        min="1"
                        step="1"
                        placeholder="limit"
                        v-model="blogFilterLimit"
                    >
                    </vs-input>
                  </div>
                </div>

                <div class="mt-1 d-flex align-items-center">
                  <div style="width: 50%;">
                    Highlighted posts
                  </div>
                  <vs-switch v-model="blogHighlighted" color="#46c93a"/>
                </div>

              </div>
            </div>

          </div>
          <!-- End Data Settings Tab-->

          <!-- Events Settings Tab -->
          <div class="card-body p-2" v-if="events" v-show="active_tab === 'events'">

            <!--Form events-->
            <div class="mt-1" v-if="events && events.submit">
              <div class="setting-item" @click="show_form_section = !show_form_section">
                <b>Gửi Form</b>
                <i v-show="show_form_section" class="fas fa-caret-down"></i>
                <i v-show="!show_form_section" class="fas fa-caret-right"></i>
              </div>
              <div v-show="show_form_section">
                <div
                    v-for="(value, name) in events.submit"
                    :key="name"
                    class="d-flex justify-content-between align-items-center"
                >
                  <div style="width: 50%;">
                    {{ name | eventComponent }}
                  </div>
                  <vs-select
                      color="#7d33ff"
                      v-if="name === 'open-dialog'"
                      v-model="events.submit['open-dialog']"
                  >
                    <vs-option label="Không chọn" value="0">
                      Không chọn
                    </vs-option>
                    <vs-option
                        v-for="d in $store.state.activePopups"
                        :key="d.component_data.attr['name']"
                        :label="d.component_data.attr.name"
                        :value="d.component_data.attr['popup-id']"
                    >
                      {{ d.component_data.attr.name }}
                    </vs-option>
                  </vs-select>
                </div>
              </div>
            </div>
            <!-- End Form Events-->

            <!--Click Events-->
            <div class="mt-1">
              <div class="setting-item" @click="show_click_section = !show_click_section">
                <b>Click</b>
                <i v-show="show_click_section" class="fas fa-caret-down"></i>
                <i v-show="!show_click_section" class="fas fa-caret-right"></i>
              </div>

              <div v-if="events.click['open-link'] !== undefined && show_click_section">
                <div class="mt-1 d-flex">
                  <div style="width: 50%;" class="d-flex flex-column justify-content-center">
                    <div>
                      Mở Link
                    </div>
                  </div>

                  <div class="w-100">
                    <vs-select
                        color="#7d33ff"
                        filter
                        v-model="linkIndex"
                        placeholder="Chọn một link trong website của bạn">
                      <vs-option
                          v-for="(link, i) in links"
                          :key="i"
                          :label="link.name"
                          :value="i + 1"
                      >
                        {{ link.name }}
                      </vs-option>
                    </vs-select>
                    <vs-input
                        class="mt-1"
                        v-model="events.click['open-link']"
                        type="url"
                        placeholder="Hoặc paste link bất kỳ vào đây..."
                    />
                  </div>
                </div>

                <div class="mt-1 d-flex justify-content-between align-items-center">
                  <div style="width: 50%;">
                    Open in new tab
                  </div>

                  <div class="w-100 d-flex justify-content-end">
                    <vs-switch v-model="targetEventBlank" color="#46c93a"/>
                  </div>
                </div>

                <div v-if="events.click['apply-filter'] !== undefined && (is_blog_page || is_shop_page)"
                     class="mt-1 d-flex justify-content-between align-items-center">
                  <div v-if="is_shop_page" style="width: 50%;">
                    Áp dụng lọc sản phẩm
                  </div>
                  <div v-else style="width: 50%;">
                    Áp dụng lọc bài viết
                  </div>
                  <vs-select
                      color="#7d33ff"
                      v-model="events.click['apply-filter']"
                      placeholder="Chọn một tham số lọc"
                  >
                    <vs-option label="Không chọn" value="0">
                      Không chọn
                    </vs-option>
                    <vs-option
                        v-for="filter in filters"
                        :key="filter.name"
                        :label="filter.name"
                        :value="filter.value"
                    >
                      {{ filter.name }}
                    </vs-option>
                  </vs-select>
                </div>
                <div v-if="events.click['open-dialog'] !== undefined"
                     class="mt-1 d-flex justify-content-between align-items-center">
                  <div style="width: 50%;">
                    Mở pop-up
                  </div>
                  <vs-select color="#7d33ff" v-model="events.click['open-dialog']">
                    <vs-option label="Không chọn" value="0">
                      Không chọn
                    </vs-option>
                    <vs-option
                        v-for="d in $store.state.activePopups"
                        :key="d.component_data.attr['name']"
                        :label="d.component_data.attr.name"
                        :value="d.component_data.attr['popup-id']"
                    >
                      {{ d.component_data.attr.name }}
                    </vs-option>
                  </vs-select>
                </div>

                <div class="mt-1"
                     v-if="searchParams('dev') && editing && editing.component_data && editing.component_data.attr">
                  <div class="mb-2">
                    Mã js (dành cho nhà phát triển)
                  </div>
                  <textarea class="w-100 bg-white" v-model="editing.component_data.attr.onclick" name="" id="" cols="30"
                            rows="10"></textarea>
                </div>
              </div>
            </div>
            <!--End Click Events-->

            <!--Scroll Events-->
            <div class="mt-1">
              <div class="setting-item" @click="show_scroll_section = !show_scroll_section">
                <b>Scroll</b>
                <i v-show="show_scroll_section" class="fas fa-caret-down"></i>
                <i v-show="!show_scroll_section" class="fas fa-caret-right"></i>
              </div>
              <div v-show="show_scroll_section">
                <div
                    v-for="(value, name) in events.scroll"
                    :key="name"
                    class="mt-1 d-flex justify-content-between align-items-center"
                >
                  <div style="width: 50%;">
                    {{ name | eventComponent }}
                  </div>
                  <vs-select
                      color="#7d33ff"
                      v-if="name === 'open-dialog'"
                      v-model="events.scroll['open-dialog']"
                  >
                    <vs-option label="Không chọn" value="0">
                      Không chọn
                    </vs-option>
                    <vs-option
                        v-for="d in $store.state.activePopups"
                        :key="d.component_data.attr['name']"
                        :label="d.component_data.attr.name"
                        :value="d.component_data.attr['popup-id']"
                    >
                      {{ d.component_data.attr.name }}
                    </vs-option>
                  </vs-select>
                </div>
              </div>
            </div>
            <!--End Scroll Events-->

          </div>
          <!-- End Events Settings Tab -->

          <!-- Embed Settings Tab -->
          <div class="card-body p-2" v-if="events" v-show="active_tab === 'embed'">

            <!--Form events-->
            <div class="mt-1">
              <label class="p-0 color-dark" for="">Paste nội dung code của bạn vào đây</label>
              <textarea v-model="editing.embed" placeholder="Paste nội dung code của bạn vào đây" rows="10"
                        class="w-100 bg-white"/>
            </div>
            <!-- End Form Events-->
            <div class="mt-1">
              <vs-button flat success class="px-4 m-0" @click="editing.save()">Lưu</vs-button>
            </div>
          </div>
          <!-- End Embed Settings Tab -->
        </div>
      </div>
    </vs-dialog>

    <!--Setting Tab Dialog-->

    <PopupStyle/>

    <!--Edit HTML Content Dialog-->
    <RichTextEditor v-if="$store.state.editing_html_content"/>

    <ControlBar :openSettingMenu="openSettingMenu"/>

    <setting-menu v-if="editing && editing.$options.name === 'BaseMenuComponent'" />
  </div>
</template>

<script>
const axios = require("axios").default;

import SettingMenu from "@/builder_components/SettingMenu";
import ColourPicker from "@/builder_components/ColourPicker";
import EditorTabs from "@/builder_components/EditorTabs";
import PopupStyle from "@/builder_components/PopupStyle";

const RichTextEditor = () => import("@/builder_components/RichTextEditor")

import {apiUpload, apiDelete} from "@/apis/upload.js";
import {apiGetGallery} from "@/apis/gallery.js";
import {api} from "@/apis/api.js";
import {apiGetForms} from "@/apis/forms.js";
import {is_array, is_empty, is_object} from "@/components/base/utils"

// import { get_esc_data } from  "./base/utils"

import ControlBar from "@/builder_components/ControlBar";
import * as animationData from '@/assets/animation/delete.json';

import 'he-tree-vue/dist/he-tree-vue.css' // base style
import "../assets/base_variable.css"

export default {
  name: "QuickEditor",
  components: {
    ControlBar,
    SettingMenu,
    ColourPicker,
    EditorTabs,
    PopupStyle,
    RichTextEditor
  },
  data: () => {
    return {
      defaultOptions: {animationData: animationData.default},
      loading: false,
      editSelect2: false,
      createMenuDialog: false,
      editMenuDialog: false,
      removeMenuDialog: false,
      active: false,
      onclickCode: '',
      activeMenu: '',
      pathMenu: '',
      styleSetting: {
        leftSetting: window.innerWidth - 405,
        topSetting: 5,
        old_mouse_x: 0,
        old_mouse_y: 0
      },
      leftOldSetting: 0,
      topOldSetting: 0,
      src: null,
      isFontMenu: false,
      isDialogUpload: false,
      openedSettings: false,
      images: [
        // {
        //   url: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png'
        // }
      ], // list galerry
      form_index: 0,
      forms: null,
      origin_form: null,
      urlImage: '', // url image do not in galerry
      preview: '',
      height: 29,
      show_click_section: true,
      show_scroll_section: true,
      show_carousel_play_section: true,
      show_carousel_items_section: true,
      show_carousel_blog_section: true,
      show_carousel_product_section: true,
      show_form_section: true,
      active_tab: '',
      tab_selector_style: {}
    };
  },
  mounted() {
    let RO = new ResizeObserver(entries => {
      for (let entry of entries) this.height = entry.contentRect.height
    })
    RO.observe(this.$el);
    document.addEventListener('keydown', this.document_key_down)
  },
  watch: {
    active_tab(val) {
      let tab
      if (val === 'carousel') tab = this.$refs.carousel_tab
      else if (val === 'data') tab = this.$refs.data_tab
      else if (val === 'events') tab = this.$refs.events_tab
      else if (val === 'video') tab = this.$refs.video_tab
      else if (val === 'embed') tab = this.$refs.embed_tab
      if (tab) {
        let {left, width} = tab.getBoundingClientRect()
        let left_tabs_wrapper = this.$refs.settings.$el.querySelector('nav.tabs').getBoundingClientRect().left
        let left_diff = left - left_tabs_wrapper
        this.tab_selector_style = {
          left: `${left_diff}px`,
          width: `${width}px`
        }
      }
    },
    form_index(value) {
      if (this.editing && 'deepsel-form-data' in this.editing.component_data.attr) {
        this.form = value
        let origin_form = JSON.stringify(this.origin_form)
        if (value == false) {
          this.editing.component_data.attr = JSON.parse(origin_form).attr
          this.editing.component_data.child = JSON.parse(origin_form).child
        } else {
          value -= 1
          this.editing.component_data.attr['deepsel-form-data'] = this.forms[value].id
          let form = this.forms[value]
          let form_fields = form.fields
          let formFields = form_fields.map(v => {
            switch (v.type) {
              case 'select': {
                let select_component = this.find_form_field(JSON.parse(origin_form), 'deepsel-form-select')
                if (select_component) {
                  if (select_component.attr) {
                    delete select_component.attr['deepsel-form-' + v.type]
                    delete select_component.attr['hidden']
                  }
                  if (select_component.attr && select_component.attr.class) {
                    if (is_array(select_component.attr.class)) select_component.attr.class = select_component.attr.class.join(' ')
                    select_component.attr.class = select_component.attr.class.replace(/(^|\s)col-\S+/g, '') + ' col-lg-' + 12 * eval(v.size)
                  }
                  let select_tag = this.find_form_tag(select_component, v.type)
                  if (select_tag) {
                    if (select_tag.attr) {
                      delete select_tag.attr['hidden']
                      select_tag.attr.name = v.name
                      select_tag.attr['data-placeholder'] = v.name
                      select_tag.attr.is_required = v.is_required
                    }
                    select_tag.child = v.selectable_values.map(o => ({
                      tag: 'option',
                      attr: {
                        value: o.value,
                      },
                      child: [{
                        node: 'text',
                        text: o.name
                      }],
                      node: 'element'
                    }))
                  }
                  return select_component
                }
                return {
                  tag: 'select',
                  attr: {
                    class: ' col-lg-' + 12 * eval(v.size),
                    // ['deepsel-form-' + v.type]: '',
                    is_required: v.is_required
                  },
                  child: v.selectable_values.map(o => ({
                    tag: 'option',
                    attr: {
                      value: o.value,
                    },
                    child: [{
                      node: 'text',
                      text: o.name
                    }],
                    node: 'element'
                  })),
                  node: 'element'
                }
              }
              case 'textarea': {
                let textarea_component = this.find_form_field(JSON.parse(origin_form), 'deepsel-form-textarea')
                if (textarea_component) {
                  if (textarea_component.attr) {
                    delete textarea_component.attr['deepsel-form-' + v.type]
                    delete textarea_component.attr['hidden']
                  }
                  if (textarea_component.attr && textarea_component.attr.class) {
                    if (is_array(textarea_component.attr.class)) textarea_component.attr.class = textarea_component.attr.class.join(' ')
                    textarea_component.attr.class = textarea_component.attr.class.replace(/(^|\s)col-\S+/g, '') + ' col-lg-' + 12 * eval(v.size)
                  }
                  let textarea_tag = this.find_form_tag(textarea_component, v.type)
                  if (textarea_tag && textarea_tag.attr) {
                    delete textarea_tag.attr['hidden']
                    textarea_tag.attr.name = v.name
                    textarea_tag.attr.placeholder = v.placeholder
                    textarea_tag.attr.is_required = v.is_required
                  }
                  return textarea_component
                }
                return {
                  tag: 'textarea',
                  attr: {
                    class: ' col-lg-' + 12 * eval(v.size),
                    placeholder: v.placeholder,
                    // ['deepsel-form-' + v.type]: '',
                    is_required: v.is_required
                  },
                  node: 'element'
                }
              }
              default: {
                let input_component = this.find_form_field(JSON.parse(origin_form), 'deepsel-form-input-' + v.type)
                if (input_component) {
                  if (input_component.attr) {
                    delete input_component.attr['deepsel-form-input-' + v.type]
                    delete input_component.attr['hidden']
                  }
                  if (input_component.attr && input_component.attr.class) {
                    if (is_array(input_component.attr.class)) input_component.attr.class = input_component.attr.class.join(' ')
                    input_component.attr.class = input_component.attr.class.replace(/(^|\s)col-\S+/g, '') + ' col-lg-' + 12 * eval(v.size)
                  }
                  let input_tag = this.find_form_tag(input_component, 'input', v.type)
                  if (input_tag && input_tag.attr) {
                    delete input_tag.attr['hidden']
                    input_tag.attr.placeholder = v.placeholder
                    input_tag.attr.name = v.name
                    input_tag.attr.value = v.value
                    input_tag.attr.is_required = v.is_required
                  }
                  let label_tag = this.find_form_tag(input_component, 'label')
                  if (label_tag) {
                    let text_node = label_tag.child.find(n => n.text)
                    if (text_node) text_node.text = v.label
                    else label_tag.child.push({
                      node: 'text',
                      text: ' ' + v.label
                    })
                  }
                  return input_component
                }
                return v.label ? {
                  tag: 'label',
                  attr: {
                    class: 'col-lg-' + 12 * eval(v.size),
                    is_required: v.is_required
                  },
                  child: [{
                    tag: 'input',
                    attr: {
                      type: v.type,
                      placeholder: v.placeholder,
                      // ['deepsel-form-input-' + v.type]: '',
                      name: v.name,
                      value: v.value,
                    },
                    node: 'element'
                  }, {
                    node: 'text',
                    text: ' ' + v.label
                  }],
                  node: 'element'
                } : {
                  tag: 'input',
                  attr: {
                    type: v.type,
                    class: 'col-lg-' + 12 * eval(v.size),
                    placeholder: v.placeholder,
                    // ['deepsel-form-input-' + v.type]: '',
                    name: v.name,
                    value: v.value,
                    is_required: v.is_required
                  },
                  node: 'element'
                }
              }
            }
          })
          let noti_input = {
            tag: 'input',
            attr: {
              type: 'hidden',
              name: 'success_noti',
              value: form.success_noti,
            },
            node: 'element'
          }
          formFields.push(noti_input)
          let submit_component = this.find_form_field(JSON.parse(origin_form), 'deepsel-form-submit')
          // delete submit_component.attr['deepsel-form-submit']
          delete submit_component.attr['hidden']
          if (submit_component) formFields.push(submit_component)
          if (window.$('select').data('select2')) {
            window.$('select').select2('destroy');
          }
          this.editing.component_data.attr.name = form.name
          let component_child = JSON.parse(JSON.stringify(this.editing.component_data.child))
          component_child = component_child.filter(c => {
            let del = true
            if (c.attr) {
              for (const a in c.attr) {
                if (a.includes('deepsel-form')) del = false
              }
            }
            if (!del) return c
          })
          component_child.forEach(c => {
            c.attr['hidden'] = ''
            let input_tag = this.find_form_tag(c, 'input')
            if (input_tag) input_tag.attr['hidden'] = ''
            let textarea_tag = this.find_form_tag(c, 'textarea')
            if (textarea_tag) textarea_tag.attr['hidden'] = ''
            let select_tag = this.find_form_tag(c, 'select')
            if (select_tag) select_tag.attr['hidden'] = ''
          })
          this.editing.component_data.child = component_child.concat(formFields)
        }
      }
    },
    urlImage(val) {
      setTimeout(() => {
        let preview = this.$refs.preview
        this.preview = {
          width: preview.naturalWidth,
          height: preview.naturalHeight
        };
      }, 500)
      if (this.editing.$options.name !== 'BaseDetailBlogComponent') {
        this.background_image !== undefined ? (this.background_image = val) : '';
        this.imageUrl !== undefined ? (this.imageUrl = val) : '';
      }
    },
    preview() {
      return {}
    },
    async openedSettings(val) {
      let body = document.querySelector("body")
      if (val) {
        body.style.overflow = "hidden"
      } else {
        body.style.overflow = ""
      }
      if (this.editing && 'deepsel-form-data' in this.editing.component_data.attr) {
        if (!this.origin_form) this.origin_form = JSON.parse(JSON.stringify(this.editing.component_data))
        let res = await apiGetForms()
        this.forms = res.data
        this.form_index = this.forms.findIndex(f => f.id == this.editing.component_data.attr["deepsel-form-data"]) + 1
      }
    },
    isDialogUpload(newV) {
      if (newV) {
        if (this.editing.$options.name === 'BaseDetailBlogComponent') {
          this.urlImage = this.$store.state.blog_detail.image
        } else {
          let url = this.background_image ? this.background_image : "" || this.imageUrl ? this.imageUrl : "";
          this.urlImage = url
        }
        apiGetGallery().then((res) => this.images = res.data)
      }
    },
  },
  computed: {
    isPopupStyle: {
      get() {
        return this.$store.state.isPopupStyle
      },
      set(val) {
        this.$store.state.isPopupStyle = val
      }
    },
    origin() {
      return window.parent.location.origin
    },
    linkIndex: {
      get() {
        return this.links.findIndex(e => e.path === this.events.click['open-link']) + 1
      },
      set(val) {
        if (this.$store.state.editing_mail) this.events.click['open-link'] = window.parent.location.origin + this.links[val - 1].path
        else this.events.click['open-link'] = this.links[val - 1].path
      }
    },

    arrMenu() {
      let menu = []
      const push_menu = (e, index) => {
        menu = [...menu, {...this.lodash.omit(e, 'children'), ...{sequence: index}}]
        if (!this.lodash.isEmpty(e.children)) e.children.forEach((e, index) => push_menu(e, index))
      }
      if (this.$store.state.common_data.menus) {
        this.$store.state.common_data.menus.forEach((e, index) => {
          push_menu(e, index)
        })
      }
      return menu
    },
    tooltip_bottom() {
      let top = this.style_obj.top
      if (top) {
        return parseInt(top.replace('px', '')) <= 30
      }
      return false
    },
    dev_mode() {
      return this.searchParams('dev') || process.env.NODE_ENV === "development"
    },
    is_shop_page() {
      if (process.env.NODE_ENV === "development") return false
      let path = window.parent.location.pathname.split("/")
      return path[path.length - 1] === 'shop'
    },
    is_blog_page() {
      if (process.env.NODE_ENV === "development") return true
      let path = window.parent.location.pathname.split("/")
      return path[path.length - 1] === 'blogs' && is_object(this.component_data.attr) && 'is-blog-page' in this.component_data.attr
    },
    blogFilterCategory: {
      get() {
        if (this.editing.isCarousel && this.editing.$parent.component_data.attr) {
          return this.editing.$parent.component_data.attr['blog-filter-category'] || '0'
        }

        if (this.editing && this.editing.component_data.attr && this.editing.component_data.attr['blog-filter-category']) {
          return this.editing.component_data.attr['blog-filter-category']
        } else return '0';
      },
      set(value) {
        if (this.editing) {
          if (this.editing.isCarousel && this.editing.$parent.component_data.attr) {
            this.editing.$parent.change_filter(value === '0' ? '' : value, 'blog-filter-category')
          } else {
            this.editing.change_filter(value === '0' ? '' : value, 'blog-filter-category')
          }
        }
      },
    },
    blogFiltertag: {
      get() {
        if (this.editing.isCarousel && this.editing.$parent.component_data.attr) {
          return this.editing.$parent.component_data.attr['blog-filter-tag'] || '0'
        }
        if (this.editing && this.editing.component_data.attr && this.editing.component_data.attr['blog-filter-tag']) {
          return this.editing.component_data.attr['blog-filter-tag']
        } else return '0';
      },
      set(value) {
        if (this.editing) {
          if (this.editing.isCarousel && this.editing.$parent.component_data.attr) {
            this.editing.$parent.change_filter(value === '0' ? '' : value, 'blog-filter-tag')
          } else {
            this.editing.change_filter(value === '0' ? '' : value, 'blog-filter-tag')
          }
        }
      },
    },
    blogFilterLimit: {
      get() {
        if (this.editing.isCarousel && this.editing.$parent.component_data.attr) {
          return this.editing.$parent.component_data.attr['blog-filter-limit'] || 10
        }
        if (this.editing && this.editing.component_data.attr && this.editing.component_data.attr['blog-filter-limit']) {
          return this.editing.component_data.attr['blog-filter-limit']
        } else return 10;
      },
      set(value) {
        if (this.editing) {
          if (this.editing.isCarousel && this.editing.$parent.component_data.attr) {
            this.editing.$parent.change_filter(value, 'blog-filter-limit')
          } else {
            this.editing.change_filter(value, 'blog-filter-limit')
          }
        }
      },
    },
    blogHighlighted: {
      get() {
        if (this.editing.isCarousel && this.editing.$parent.component_data.attr) {
          return this.editing.$parent.component_data.attr['blog-highlighted'] || false
        }
        if (this.editing && this.editing.component_data.attr && this.editing.component_data.attr['blog-highlighted']) {
          return this.editing.component_data.attr['blog-highlighted']
        } else return false

      },
      set(value) {
        if (this.editing) {
          if (this.editing.isCarousel && this.editing.$parent.component_data.attr) {
            this.editing.$parent.change_filter(value, 'blog-highlighted')
          } else {
            this.editing.change_filter(value, 'blog-highlighted')
          }
        }
      },
    },
    filters() {
      try {
        let sort_filters = []
        let common_data = this.$store.state.common_data
        if (this.is_shop_page) {
          sort_filters = [
            {
              name: 'Giá: Thấp đến cao',
              value: 'sort=price_asc'
            },
            {
              name: 'Giá: Cao đến thấp',
              value: 'sort=price_desc'
            },
            {
              name: 'Bán chạy',
              value: 'sort=selling'
            },
            {
              name: 'Mới nhất',
              value: 'sort=new'
            },
            {
              name: 'Tên A-Z',
              value: 'sort=name_asc'
            },
            {
              name: 'Tên Z-A',
              value: 'sort=name_desc'
            }
          ]
          let categories = common_data.categories_id ? Object.keys(common_data.categories_id).map(key => {
            return {
              name: `Nhóm hàng: ${common_data.categories_id[key]}`,
              value: `category_id=${key}`
            }
          }) : []
          let tags = common_data.product_tags ? common_data.product_tags.map(tag => {
            return {
              name: `Tag: ${tag.name}`,
              value: `tag_id=${tag.id}`
            }
          }) : []
          let collections = common_data.collections ? common_data.collections.map(collection => {
            return {
              name: `Bộ sưu tập: ${collection.name}`,
              value: `collection=${collection.string_id}`
            }
          }) : []
          let brands = common_data.brands ? Object.keys(common_data.brands).map(key => {
            return {
              name: `Nhãn hiệu: ${common_data.brands[key]}`,
              value: `brand_id=${key}`
            }
          }) : []

          return sort_filters
              .concat(categories)
              .concat(tags)
              .concat(collections)
              .concat(brands)
        } else if (this.is_blog_page) {
          let blog_categories = Object.keys(common_data.blog_categories).map(key => {
            return {
              name: `Thể loại blog: ${common_data.blog_categories[key]}`,
              value: `category=${common_data.blog_categories[key]}`
            }
          })
          let blog_tags = Object.keys(common_data.blog_tags).map(key => {
            return {
              name: `Tag blog: ${common_data.blog_tags[key]}`,
              value: `tag=${common_data.blog_tags[key]}`
            }
          })
          // let blog_authors = Object.keys(common_data.blog_authors).map(key => {
          //   return {
          //     name: `Tác giả blog: ${common_data.blog_authors[key]}`,
          //     value: `author=${common_data.blog_authors[key]}`
          //   }
          // })

          return sort_filters
              .concat(blog_categories)
              // .concat(blog_authors)
              .concat(blog_tags)
        } else {
          return []
        }
      } catch (error) {
        console.error("Đã có lỗi xảy ra", error)
        console.log("filters > computed > QuickEditor")
        return ''
      }
    },
    links() {
      try {
        let res = []

        let common_data = this.$store.state.common_data

        let pages = common_data.pages.map(page => {
          if (page.path === 'home') return {
            name: `Trang: ${page.name}`,
            path: '/'
          }
          return {
            name: `Trang: ${page.name}`,
            path: '/' + page.path
          }
        })
        let categories = common_data.categories_id ? Object.keys(common_data.categories_id).map(key => {
          return {
            name: `Nhóm hàng: ${common_data.categories_id[key]}`,
            path: `/shop#category_id=${key}`
          }
        }) : []
        let tags = common_data.product_tags ? common_data.product_tags.map(tag => {
          return {
            name: `Tag: ${tag.name}`,
            path: `/shop#tag_id=${tag.id}`
          }
        }) : []
        let collections = common_data.collections ? common_data.collections.map(collection => {
          return {
            name: `Bộ sưu tập: ${collection.name}`,
            path: `/shop#collection=${collection.string_id}`
          }
        }) : []
        let brands = common_data.brands ? Object.keys(common_data.brands).map(key => {
          return {
            name: `Nhãn hiệu: ${common_data.brands[key]}`,
            path: `/shop#brand_id=${key}`
          }
        }) : []

        let blog_categories = Object.keys(common_data.blog_categories).map(key => {
          return {
            name: `Thể loại blog: ${common_data.blog_categories[key]}`,
            path: `/blogs?category=${key}`
          }
        })
        let blog_tags = Object.keys(common_data.blog_tags).map(key => {
          return {
            name: `Tag blog: ${common_data.blog_tags[key]}`,
            path: `/blogs?tag=${key}`
          }
        })
        // let blog_authors = Object.keys(common_data.blog_authors).map(key => {
        //   return {
        //     name: `Tác giả blog: ${common_data.blog_authors[key]}`,
        //     path: `/blogs#author=${common_data.blog_authors[key]}`
        //   }
        // })

        res = res.concat(pages)
            .concat(categories)
            .concat(tags)
            .concat(collections)
            .concat(brands)
            .concat(blog_categories)
            .concat(blog_tags)
            .concat([
              {
                name: 'Giỏ hàng',
                path: '/shop/cart'
              },
              {
                name: 'Gọi điện',
                path: 'tel: số_điện_thoại'
              },
              {
                name: 'Gửi mail',
                path: 'mailto:tên_mail'
              },
            ])
        // .concat(blog_authors)

        return res

      } catch (error) {
        console.error("đã có lỗi xảy ra", error)
        console.log("links > computed > QuickEditor");
        return ''
      }
    },
    common_data() {
      return this.$store.state.common_data
    },
    background_image: {
      get() {
        if (this.editing && this.editing.component_data.attr && this.editing.component_data.attr.style) {
          let editing = this.editing.is_empty_shell ? this.editing.$children[0] : this.editing
          let img_src = editing.component_data.attr.style.backgroundImage;

          if (img_src) {
            img_src = img_src.replace("url(", "").replace(")", "").replaceAll("'", "")
          }
          return img_src;
        } else return null;
      },
      set(value) {
        if (this.editing && this.editing.component_data.attr && this.editing.component_data.attr.style) {
          let editing = this.editing.is_empty_shell ? this.editing.$children[0] : this.editing
          editing.component_data.attr.style.backgroundImage = `url(${value})`;
        }
      },
    },
    autoplayTimeout: {
      get() {
        if (this.editing) {
          return this.editing.optionsCarousel.autoplayTimeout;
        } else return null;
      },
      set(value) {
        if (this.editing) {
          this.editing.optionsCarousel.autoplayTimeout = parseInt(value)
        }
      },
    },
    autoplay: {
      get() {
        if (this.editing) {
          return this.editing.optionsCarousel.autoplay;
        } else return false;
      },
      set(value) {
        if (this.editing) {
          this.editing.optionsCarousel.autoplay = value
        }
      },
    },
    loop: {
      get() {
        if (this.editing) {
          return this.editing.optionsCarousel.loop;
        } else return false;
      },
      set(value) {
        if (this.editing) {
          this.editing.optionsCarousel.loop = value
        }
      },
    },
    optionsCarousel: {
      get() {
        if (this.editing) {
          return this.editing.optionsCarousel;
        } else return null;
      },
      set(value) {
        if (this.editing) {
          this.editing.optionsCarousel = value
        }
      },
    },
    imageUrl: {
      get() {
        if (this.editing) {
          let editing = this.editing.is_empty_shell ? this.editing.$children[0] : this.editing
          let src = editing.component_data.attr['data-src'] || editing.component_data.attr.src
          return is_array(src) ? src.join(" ") : src
          // return editing.component_data.attr.src;
        } else return null;
      },
      set(value) {
        if (this.editing) {
          let editing = this.editing.is_empty_shell ? this.editing.$children[0] : this.editing
          if (editing.component_data.attr['data-src']) editing.component_data.attr['data-src'] = `${value}`
          editing.component_data.attr.src = `${value}`;

        }
      },
    },
    color: {
      get() {
        if (this.editing && this.editing.component_data.attr && this.editing.component_data.attr.style) {
          return this.editing.component_data.attr.style.color;
        } else return null;
      },
      set(value) {
        if (this.editing && this.editing.component_data.attr && this.editing.component_data.attr.style) {
          this.editing.component_data.attr.style.color = value;
        }
      },
    },
    classList: {
      get() {
        if (this.editing && this.editing.component_data.attr && this.editing.component_data.attr.class) {
          return is_array(this.editing.component_data.attr.class) ? this.editing.component_data.attr.class.join(',') : this.editing.component_data.attr.class
        } else return null;
      },
      set(value) {
        if (this.editing) {
          this.editing.component_data.attr.class = value.split(',');
        }
      },
    },
    form_data() {
      return this.editing && this.editing.$options.name === 'BaseFormDataComponent'
    },
    editing() {
      return this.$store.state.editing;
    },
    events() {
      if (this.editing) {
        if (this.$store.state.editing_mail) {
          if (this.editing.$options.name !== 'BaseA') return false
          else return {
            'click': {
              'open-link': ''
            }
          }
        }
        // let editing = this.editing.is_empty_shell ? this.editing.$children[0] : this.editing
        let editing = this.editing

        if (this.editing.$options.name !== 'BaseA') {
          let tag_a = this.find_child_tag_a(this.editing) || this.find_parent_tag_a(this.editing)
          if (tag_a) editing = tag_a
        }

        return editing.component_data.events;

      } else return false;
    },
    targetEvent: {
      get() {
        if (this.events) {
          return this.events.target
        }
        return false
      },
      set(val) {
        if (val && this.events) this.events.target = val
      }
    },
    targetEventBlank: {
      get() {
        return this.targetEvent === '_blank'
      },
      set(val) {
        if (val) this.targetEvent = '_blank'
        else this.targetEvent = '_self'
      }
    },

    editing_text() {
      // if (this.editing) {
      //   let has_text = false
      //
      //   if (is_array(this.editing.$props.component_data.child)) {
      //     let child_text_nodes = this.editing.$props.component_data.child.filter(c => c.node === 'text')
      //     if (child_text_nodes.length > 0) has_text = true
      //   }
      //
      //   let res = this.editing.is_text && this.editing.editing_text && has_text
      //   // console.log(res, this.editing.tag, this.editing.is_text, this.editing.editing_text, has_text)
      //   return res
      // }
      // return false;

      return this.$store.state.editing_text
    },
    editing_carousel: {
      get() {
        return this.$store.state.editing_carousel
      },
      set(val) {
        this.$store.state.editing_carousel = val
      }
    },
    is_text() {
      if (this.editing) {
        let has_text = false
        if (is_array(this.editing.$props.component_data.child)) {
          let text = this.find_text_node(this.editing.$props.component_data)
          if (text) has_text = true
        }

        return this.editing.is_text && has_text
      }
      return false
    },
    is_image() {
      return this.background_image !== undefined || this.imageUrl !== undefined;
    },
    is_blogl_detail_content() {
      if (this.editing) {
        return this.editing.$options.name === 'BaseDetailBlogComponent'
      }
      return false
    },
    is_html_content() {
      return this.editing && this.editing.$options.name === 'BaseHTMLContentComponent'
    },
    style_obj() {
      if (this.editing) {
        let scroll_top = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
        let scroll_left = window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft || 0
        // let editor_height = this.$el ? this.$el.getBoundingClientRect().height : 29
        let editor_height = this.height
        let {top, left, height} = this.editing.$el.getBoundingClientRect()

        if (top < editor_height) {
          top += height
        } else {
          top -= editor_height
        }
        if (left < 0) left = 0

        if (scroll_top > 0) top += scroll_top
        if (scroll_left > 0) left += scroll_left

        return {
          display: "block",
          top: `${top}px`,
          left: `${left}px`,
        };
      } else {
        return {
          display: "none",
        };
      }
    },
    bold() {
      if (this.editing && this.editing.component_data.attr && this.editing.component_data.attr.style) {
        return this.editing.component_data.attr.style.fontWeight === "bold";
      } else return false;
    },
    italic() {
      if (this.editing && this.editing.component_data.attr && this.editing.component_data.attr.style) {
        return this.editing.component_data.attr.style.fontStyle === "italic";
      } else return false;
    },
    strike() {
      if (this.editing && this.editing.component_data.attr && this.editing.component_data.attr.style) {
        return (
            this.editing.component_data.attr.style.textDecoration ===
            "line-through"
        );
      } else return false;
    },
    deepselEsc: {
      get() {
        let editing = this.editing && this.editing.is_empty_shell ? this.editing.$children[0] : ''
        if (editing) return editing.component_data.attr["deepsel-esc"]
        else return ''
      },
      set(val) {
        let editing = this.editing.is_empty_shell ? this.editing.$children[0] : this.editing
        editing.change_esc(val)
      }
    },
    collection: {
      get() {
        if (this.editing && this.editing.isCarousel && this.editing.isProductListsCollection) return this.editing.$parent.component_data.attr["deepsel-multi-product-collection"]
        else return this.editing.component_data.attr["deepsel-multi-product-collection"]
      },
      set(val) {
        if (this.editing && this.editing.isCarousel && this.editing.isProductListsCollection) return this.editing.$parent.change_collection(val)
        else this.editing.change_collection(val)
      }
    },
  },
  methods: {
    async manage_forms() {
      let redirect_url
      if (parseInt(this.form_index)) {
        redirect_url = `/web#view_type=form&model=form.management&id=${this.forms[this.form_index - 1].id}`
      } else {
        redirect_url = '/web'
        let res = await api({
          jsonrpc: "2.0",
          params: {
            model: "ir.model.data",
            method: 'get_object_reference',
            args: ['form_management', 'menu_form_management']
          }
        })
        let {result} = res.data
        if (is_array(result) && !is_empty(result)) {
          let menu_id = result[1]
          redirect_url += `#menu_id=${menu_id}`
        }
      }
      window.open(redirect_url)
    },
    tabs_mounted() {
      let editing = this.editing
      let that = this
      setTimeout(function () {
        if (editing && editing.isCarousel) {
          that.active_tab = 'carousel'
        } else if (editing.isVideo) {
          that.active_tab = 'video'
        } else if (that.editing.$options.name === 'BaseEmbedComponent') {
          that.active_tab = 'embed'
        } else if ((that.deepselEsc !== undefined && editing.is_text) || that.form_data || ['BaseMultiBlogComponent', 'BaseMultiProductComponent'].includes(that.editing.$options.name)) {
          that.active_tab = 'data'
        } else if (that.events) {
          that.active_tab = 'events'
        }
      }, 300)
    },
    updateTree() {
      setTimeout(() => {
        let menu = []
        const push_menu = (item, index, parent_id = false) => {
          menu = [...menu, {...item, ...{sequence: index, parent_id}}]
          if (!this.lodash.isEmpty(item.children)) {
            item.children.forEach((e, index) => push_menu(e, index, item.id))
          }
        }
        this.$store.state.common_data.menus.forEach((e, index) => push_menu(e, index))
        this.loading = true

        axios.post("/menus", {
          "jsonrpc": "2.0",
          "method": "call",
          "params": {
            "datas": menu
          }
        })
            .then(() => this.loading = false)
            .catch(() => this.loading = false)

      }, 200)
    },
    changeMenu(method) {
      if (method === 'create') {
        api({
          jsonrpc: "2.0",
          model: "header.menu",
          method: 'call',
          params: {
            model: 'header.menu',
            method: 'create',
            args: [{
              name: this.activeMenu.name,
              parent_id: this.activeMenu.parent_id ? this.activeMenu.parent_id : false
            }]
          }
        })
            .then(() => {
              this.createMenuDialog = false
              this.getMenuFromApi()
            })
      } else if (method === 'edit') {
        api({
          jsonrpc: "2.0",
          model: "header.menu",
          method: 'call',
          params: {
            model: 'header.menu',
            method: 'write',
            args: [[this.activeMenu.id], {name: this.activeMenu.name}]
          }
        })
            .then(() => {
              this.editMenuDialog = false
              this.getMenuFromApi()
            })
      } else if (method === 'unlink') {
        api({
          jsonrpc: "2.0",
          model: "header.menu",
          method: 'call',
          params: {
            model: 'header.menu',
            method: 'unlink',
            args: [[this.activeMenu.id]]
          }
        })
            .then(() => {
              this.removeMenuDialog = false
              this.getMenuFromApi()
            })
      }
    },
    openSettingMenu() {
      // this.settingMenuDialog = true
    },
    async getMenuFromApi() {
      let data = await axios.get(`/common.json`) // common
      this.$store.state.common_data.menus = data.data.menus
      window.$("*[deepsel-menu-item]").click(function ($event) {
        $event.preventDefault()
        $event.stopPropagation()
        window.$(this).parent().find("*[deepsel-menu-item]").removeClass("active")
        window.$(this).toggleClass('active')
      })
    },
    setting() {
      if (this.editing.$options.name === 'BaseMenuComponent') {
        this.$store.state.settingMenuDialog = true
        return
      }
      this.openedSettings = true
    },
    document_key_down(event) {
      let e_pressed = false

      if (event.key !== undefined) {
        if (event.key === 'e') e_pressed = true
      } else if (event.keyCode !== undefined) {
        if (event.keyCode === 69) e_pressed = true
      }

      if (e_pressed && (event.metaKey || event.ctrlKey)) {
        if (this.is_text || this.is_html_content) this.toggle_edit_text()
      }
    },
    toggle_edit_text() {
      if (this.editing) this.editing.toggle_edit_text()
    },
    searchParams(c) {
      let url = new URL(window.parent.location.href);
      return url.searchParams.get(c);
    },
    find_text_node(component_data) {
      if (!is_array(component_data.child)) return null
      for (let child of component_data.child) {
        if (child.node === 'text' && /\S/.test(child.text)) return child  //text node must not contain all whitespace
        let res = this.find_text_node(child)
        if (res) return res
      }
      return null
    },
    find_child_tag_a(component) {
      if (!is_array(component.component_data.child)) return null
      if (component.component_data.child.length !== 1) return null
      if (component.$children.length !== 1) return null

      if (component.$children[0].tag === 'a') return component.$children[0]
      else return this.find_child_tag_a(component.$children[0])
    },
    find_parent_tag_a(component) {
      if (!component.$parent) return null
      if (component.$parent.$options.name === 'BaseA') return component.$parent
      return null
    },
    find_form_field(component_data, field) {
      if (component_data.attr && field in component_data.attr) {
        return component_data;
      } else if (component_data.child != null) {
        var result = null;
        for (let i = 0; result == null && i < component_data.child.length; i++) {
          result = this.find_form_field(component_data.child[i], field);
        }
        return result
      }
      return null
    },
    find_form_tag(component_data, tag, type = undefined) {
      if (type) {
        if (component_data.tag == tag && component_data.attr && component_data.attr.type == type) {
          return component_data;
        } else if (component_data.child != null) {
          let result = null;
          for (let i = 0; result == null && i < component_data.child.length; i++) {
            result = this.find_form_tag(component_data.child[i], tag, type);
          }
          return result
        }
        return null
      } else {
        if (component_data.tag == tag && component_data.attr) {
          return component_data;
        } else if (component_data.child != null) {
          let result = null;
          for (let i = 0; result == null && i < component_data.child.length; i++) {
            result = this.find_form_tag(component_data.child[i], tag, type);
          }
          return result
        }
        return null
      }
    },
    editingCarousel() {
      // this.editing_carousel = true
      this.editing.editingCarousel()
      this.openedSettings = false
    },
    deleteImage(item, index, event) {
      console.log(event);
      event.preventDefault()
      apiDelete({
        jsonrpc: "2.0",
        method: "call",
        params: {
          model: "image.gallery",
          method: "unlink",
          args: [item.id],
        },
      }).then(() => {
        this.images.splice(index, 1);
        this.background_image === item.url ? (this.background_image = "") : "";
      });
    },
    async handleFileUpload(e) {
      let page_path = this.$store.state.page_path;
      let files = [];

      function readFileAsDataURL(file) {
        return new Promise((resolve) => {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            resolve({
              name: file.name,
              image: reader.result.split(",")[1],
            });
          };
        });
      }

      for (let i = 0; i < e.target.files.length; i++) {
        let file = await readFileAsDataURL(e.target.files[i]);
        files.push(file);
      }

      apiUpload({
        jsonrpc: "2.0",
        method: "call",
        params: {
          datas: files,
          page_path,
        },
      })
          .then((res) => {
            this.images = this.images.concat(res.data.result);
          })
          .catch((err) => console.log(err));
    },
    close() {
      this.openedSettings = false;
      // this.$store.commit("editing_component", {
      //   editing_position: null,
      // });
    },
    edit_component_setting() {
      if (this.editing) {
        this.$store.commit("editing_component_setting", this.editing);
      }
      return false;
    },
    reset_position() {
      // this.editing.attr.style.transform = null
      if (this.editing) {
        let window_width = window.innerWidth || window.documentElement.clientWidth;
        if (window_width < 600) {
          this.editing.component_data.attr.style.transform_data.mobile = null;
        } else if (window_width < 992) {
          this.editing.component_data.attr.style.transform_data.tablet = null;
        } else {
          this.editing.component_data.attr.style.transform_data.desktop = null;
        }
      }
    }
  },
};
</script>
<style scoped src="bootstrap/dist/css/bootstrap-grid.min.css"></style>
<style>
.deepsel-drag-button {
  background-color: rgba(var(--deepsel-bg-primary));
  border-radius: 20px;
  top: -2px;
  outline: none;
  z-index: 999;
  border: none;
  font-size: 14px;
  padding: 4px 6px;
  cursor: move;
  box-shadow: 0 5px 19px rgba(6, 21, 40, 0.3)
}
</style>
<style scoped lang="scss">
::v-deep {
  .checkbox-mini {
    height: 18px;
    min-width: initial;
    width: 32px;

    .vs-switch__input:checked ~ .vs-switch__circle {
      left: calc(100% - 18px);
    }

    .vs-switch__circle {
      width: 12px;
      height: 12px;
    }
  }

  .vs-select__label, .vs-input__label {
    padding: 0
  }

  .vs-button--flat:focus {
    i {
      color: white !important
    }
  }

  .vs-dialog__content {
    max-height: calc(100vh - 200px);
  }
}

.border {
  border-color: #dee2e6 !important
}

.upload-dialog {
  padding-top: 50px;
  padding-bottom: 50px;


  .image {
    .btn-delete {
      display: none;
      z-index: 12;
    }

    &:hover {
      .btn-delete {
        display: block;
      }
    }
  }

  .vs-dialog {
    min-width: 70vw;
    height: 100%;
    display: flex;
    flex-direction: column;

    .vs-dialog__content {
      flex-grow: 1;
      max-height: none;
    }
  }
}

textarea {
  border-radius: 6px;
}

.custom-tree-content {
  position: relative;
  top: 2px;
  z-index: 1;

  &.exitChild {
    margin-left: -7px;
  }
}

.my-editor {
  border-radius: 8px;
  background: #2d2d2d;
  color: #ccc !important;
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
  height: 150px;
}

::v-deep .my-editor pre {
  color: #ccc;
}

// optional
.prism-editor__textarea:focus {
  outline: none;
}

::v-deep input {
  width: 100%;
}

::v-deep .close-setting {
  font-size: 10px;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  margin: 0;

  i {
    font-size: 10px;
  }

  .vs-button__content {
    padding: 4px;
  }
}

::v-deep.action-menu {
  &:hover {
    .list {
      visibility: visible;
      right: 100%;
      opacity: 1;
    }
  }

  .list {
    z-index: 99999;
    width: 180px;
    border-radius: 8px;
    padding: .5rem .5rem;
    right: 110%;
    opacity: 0;
    top: 0;
    background-color: white;
    visibility: hidden;
    box-shadow: 0 5px 19px rgba(6, 21, 40, .3);
    transition: .2s;

    .action {
      border-radius: 8px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: .5rem;
      cursor: pointer;

      i {
        padding: 0 1rem 0 .5rem
      }

      &:hover {
        background-color: var(--deepsel-bg-light);
      }
    }

    .action.disable {
      background-color: var(--deepsel-bg-light);
      pointer-events: none;
      opacity: 0.35;
    }
  }
}

::v-deep.menu-dialog {
  padding: 0;

  .vs-dialog {
    background-color: var(--deepsel-bg-light);
  }

  .vs-dialog--scroll .vs-dialog__content {
    max-height: calc(80vh - 50px);
  }

  .menu-tree {
    height: 70vh;
    overflow: scroll;

    .tree-node {
      border-radius: 8px;
      border-left: 1px dashed;
      background-color: white;
      position: relative;
      transition: .2s;

      &:hover {
        background-color: rgb(var(--deepsel-bg-light));
      }
    }

    // .tree-children {
    //   position: relative;
    //   &:not(:first-child) {
    //     &::after {
    //       content: "";
    //       position: absolute;
    //       top: 0;
    //       height: calc(100% - 43px);;
    //       width: 5px;
    //       background-color: white;
    //     }
    //   }
    // }
  }
}

i {
  color: rgba(var(--vs-color), 1);
}

.vs-button--circle {
  border-radius: 25px !important;
}

.quick-editor {
  i {
    color: rgb(233, 64, 87);
    font-size: 12px;
  }

  font-family: 'Montserrat', sans-serif !important;
  position: absolute;
  /*top: 1%;*/
  transition: all 0.2s ease;
  /*left: 0.5%;*/
  z-index: 9999;
  box-shadow: 0 5px 19px rgba(6, 21, 40, 0.3);
  border-radius: 12px;
  background: white;
  font-size: 5px;

  .btn-font-select {
    overflow: initial;

    &:hover {
      .dropdown-menu {
        display: block;
      }
    }
  }

  .btn {
    border-radius: 1rem !important;
    font-size: 12px !important;
    /*color: #8A2387;*/
  }

  .color-pick {
    border-radius: 1rem;
    font-size: 11px;
    padding: 5px;
  }

  .color-pick .color-input {
    padding: 3px;
  }

  .dropdown-menu {
    font-size: 11px;
    border-radius: 1rem;
    box-shadow: 0 5px 19px rgba(6, 21, 40, 0.3);
    padding-left: 5px;
    padding-right: 5px;
  }

  .font-item {
    cursor: pointer;
  }

  .form-control {
    font-size: 10px;
    /*margin-left: 5px;*/
    /*margin-right: 5px;*/
  }

  .dropdown-item {
    cursor: pointer;
  }

  .weight-selector {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }

  .weight-selection {
    cursor: pointer;
  }
}

.setting-dialog {
  font-family: 'Montserrat', sans-serif !important;
}

.setting-item {
  text-transform: uppercase;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  padding-right: 10px;
  max-height: 35px;
  background: #F5F5F5;
  color: black;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card {
  border: none !important;
}

.vs-dialog {
  background: #fcfcfc !important;
}

.deepsel-component-editor {
  font-family: 'Montserrat', sans-serif !important;
  font-size: 12px;

  .header-setting {
    padding: 12px;

    .vs-button {
      color: rgba(var(--vs-color), 1);
    }

    &:hover {
      .vs-button {
        color: white;
      }
    }
  }

  .vs-select-content {
    max-width: 100%;
  }

  label {
    text-align: left;
    color: #e94057;
  }

  .form-control {
    font-size: 11px !important;
  }

  .editor-footer {
    display: flex;
    justify-content: center;
  }
}

//tabs

.wrapper {
  text-align: center;
  //margin: 50px auto;
}

.tabs {
  //margin-top: 50px;
  font-size: 12px;
  padding: 0px;
  list-style: none;
  background: #fff;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  display: inline-block;
  border-radius: 50px;
  position: relative;
}

.tabs a {
  text-decoration: none;
  color: #777;
  text-transform: uppercase;
  padding: 10px 20px;
  display: inline-block;
  position: relative;
  z-index: 1;
  transition-duration: 0.6s;
}

.tabs a.active {
  color: #fff !important;

  i {
    color: #fff !important;
  }
}

.tabs a i {
  margin-right: 5px;
  color: #777;

}

.tabs .selector {
  height: 100%;
  display: inline-block;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1;
  border-radius: 50px;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);

  background: #05abe0;
  background: -moz-linear-gradient(45deg, #05abe0 0%, #8200f4 100%);
  background: -webkit-linear-gradient(45deg, #05abe0 0%, #8200f4 100%);
  background: linear-gradient(45deg, #05abe0 0%, #8200f4 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#05abe0', endColorstr='#8200f4', GradientType=1);
}

.single-tab {
  cursor: pointer;
}

.card-body {
  min-height: 350px;
}

.tab-icon {
  height: 15px;
}

// end tabs
</style>
