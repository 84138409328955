<template>
  <div class="deepsel-control-bar" ref="printMe" v-show="!is_in_iframe">
    <!--    <button class="btn btn-light btn-circle" data-toggle="tooltip" data-placement="right" title="Thêm section mới">-->
    <!--      <i class="fas fa-plus-circle"></i>-->
    <!--    </button>-->
    {{ origin }}
    <button
        id="deepsel-save-button"
        class="btn btn-light btn-circle deepsel-save-button"
        data-toggle="tooltip"
        data-placement="right"
        title="Lưu giao diện"
        @click="save"
    >
      <i class="fas fa-save"></i>
    </button>

    <div data-toggle="tooltip" data-placement="right" title="Quản lý pop-up">
      <button class="btn btn-light btn-circle"
              @click="statusManagerPopup = true"
      >
        <i class="fas fa-window-restore"></i>
      </button>
    </div>

    <div data-toggle="tooltip" data-placement="right" title="Menu">
      <button
          class="btn btn-light btn-circle"
          @click="openSettingMenu()"
      >
        <i class="fas fa-bars"></i>
      </button>
      <img :src="img" style="position: fixed; top: 0; left: 0;height: 100vh;" alt="" srcset="">
    </div>

    <!-- manager Popup -->
    <vs-dialog
        not-center
        scroll
        overflow-hidden
        class="manager-dialog"
        prevent-close
        v-model="statusManagerPopup"
    >
      <template #header>
        <h5 class="not-margin">
          Quản lý Popup
        </h5>
        <vs-navbar
            v-model="active"
            class="manager-dialog-navbar position-relative"
        >
          <vs-navbar-item :active="active == 'actived'" id="actived">
            Đang hoạt động
          </vs-navbar-item>
          <vs-navbar-item :active="active == 'popups'" id="popups">
            Popup Mẫu
          </vs-navbar-item>
        </vs-navbar>
      </template>

      <div class="con-form h-100">
        <div v-if="active === 'actived'">
          <div
              class="d-flex"
              style="flex-wrap: wrap;"
              v-if="activePopups.length > 0"
          >
            <div
                class="px-2"
                style="max-width: 300px"
                v-for="(popup, i) in activePopups"
                :key="i"
            >
              <vs-card type="2">
                <template #img>
                  <img
                      :src="popup.preview || `https://s3.ap-east-1.amazonaws.com/cdn.deepsel.com/%E2%80%94Pngtree%E2%80%94popup_4983327.png`"/>
                </template>
                <template #text>
                  <h6>{{ popup.name }}</h6>
                </template>
                <template #interactions>
                  <vs-button
                      color="#7d33ff"
                      class="btn-chat"
                      icon
                      @click="(statusSetting = true), (activePopup = JSON.parse(JSON.stringify(popup.component_data.attr)))"
                  >
                    <i class="fa fa-cog"></i>
                  </vs-button>
                  <vs-button
                      @click="editDialog(popup.component_data.attr['popup-id'])"
                      color="rgb(59,222,200)"
                      class="btn-chat"
                      icon
                  >
                    <i class="far fa-edit"></i>
                  </vs-button>
                </template>
              </vs-card>
            </div>
          </div>
          <div v-else>
            <h3 class="text-center">
              Trống
            </h3>
          </div>
        </div>
        <div class="d-flex" style="flex-wrap: wrap;" v-if="active === 'popups'">
          <div
              class="px-2"
              style="max-width: 300px"
              v-for="(popup, i) in popups"
              :key="i"
          >
            <vs-card
                type="2"
                @click="
                statusConfirmName = true;
                activePopup = i;
              "
            >
              <template #img>
                <img :src="popup.preview" alt=""/>
              </template>
              <template #interactions>
                <vs-button>
                  Sử dụng
                </vs-button>
              </template>
            </vs-card>
          </div>
        </div>
      </div>

      <vs-dialog width="300px" not-center v-model="statusConfirmName">
        <template #header>
          <h4 class="not-margin">
            Tên Popup
          </h4>
        </template>
        <div class="con-content">
          <vs-input v-model="popupName" placeholder="Name"></vs-input>
          <small class="text-secondary">
            VD: Popup Liên hệ, Popup sản phẩm, ...
          </small>
        </div>

        <template #footer>
          <div class="con-footer">
            <vs-button @click="createPopup" transparent>
              Ok
            </vs-button>
          </div>
        </template>
      </vs-dialog>

      <vs-dialog
          width="300px"
          not-center
          v-model="statusSetting"
      >
        <template #header>
          <h6 class="not-margin">
            Tên:
          </h6>
          <vs-input v-model="activePopup.name" placeholder="Name"/>
        </template>
        <div class="con-content">
          <div>
            <b>Tự động mở khi load trang</b>
            <div class="mt-2 d-flex justify-content-between align-items-center">
              <vs-switch
                  v-model="activePopup['auto-open']"
                  style="width: 100px"
              >
                bật
              </vs-switch>
              <vs-input
                  type="number"
                  min="0"
                  icon-after
                  placeholder="Thời gian"
                  v-model="activePopup['time-open']"
              >
                <template #icon>
                  s
                </template>
              </vs-input>
            </div>
          </div>
        </div>
        <template #footer>
          <div class="con-footer justify-content-end d-flex">
            <vs-button circle class="px-3" size="small" flat @click="savePopup()">
              Lưu
            </vs-button>
          </div>
        </template>
      </vs-dialog>

    </vs-dialog>

  </div>
</template>

<script>
const axios = require("axios").default;
// import html2canvas from 'html2canvas';
import { is_object } from "@/components/base/utils";

export default {
  name: "ControlBar",
  props: {
    openSettingMenu: Function
  },
  computed: {
    is_in_iframe() {
      return window.frameElement !== null;
    },
    activePopups() {
      return this.$store.state.activePopups;
    },
    origin() {
      return window.parent.location.origin
    }
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      active: "actived",
      statusManagerPopup: false,
      statusConfirmName: false,
      statusSetting: false,
      popupName: "",
      activePopup: "",
      popups: [],
      img: ''
    };
  },
  created() {
    window.parent.document.getElementById("wrapper-save-button-close-loading")?.click()
  },
  mounted() {
    if (this.$store.state.edit_mode) {
      document.addEventListener('keydown', this.on_key_down)
    }

    let wrapper_save_button = window.parent.document.getElementById(
        "wrapper-save-button"
    );

    let wrapper_manager_dialog = window.parent.document.getElementById(
        "wrapper-manager-dialog"
    );
    if (wrapper_save_button) {
      wrapper_save_button.addEventListener("click", this.save);
    }
    if (wrapper_manager_dialog) {
      wrapper_manager_dialog.addEventListener(
          "click",
          () => (this.statusManagerPopup = !this.statusManagerPopup)
      );
    }
  },
  methods: {
    on_key_down(e) {

      let s_pressed = false

      if (event.key !== undefined) {
        if (event.key === 's') s_pressed = true
      }
      if (event.metaKey || event.ctrlKey) {
        if (s_pressed) {
          e.preventDefault();
          window.parent.document.getElementById("wrapper-save-button").click()
        }
      }
    },
    savePopup() {
      let p = this.$store.state.activePopups.find(e => {
        console.log(e.component_data.attr['popup-id']);
        return e.component_data.attr['popup-id'] === this.activePopup['popup-id']
      })
      p.change_attr(this.activePopup)
      this.statusSetting = false
    },
    editDialog(id) {
      this.statusManagerPopup = false
      window.$(`*[popup-id=${id}]`).modal('toggle')
    },
    get_header(node) {
        let header_refs = ['header', 'Header', 'HEADER']
        if (node.tag == 'header') return JSON.stringify(node) 
        if (node.attr) {
            if ('deepsel-header' in node.attr || node.attr.id && header_refs.includes(node.attr.id)) return JSON.stringify(node)
            if (node.attr.class) {
                let class_arr = node.attr.class.split(' ')
                if (class_arr.some(item => header_refs.includes(item))) return JSON.stringify(node)
            }
        }
        if (node.child) {
            for (let i = 0; i < node.child.length; i++) {
                let r = this.get_header(node.child[i])
                if (r) return r 
                else continue
            }
        }
        return ''
    },
    get_footer(node) {
        let footer_refs = ['footer', 'Footer', 'FOOTER']
        if (node.tag == 'footer') return JSON.stringify(node) 
        if (node.attr) {
            if ('deepsel-footer' in node.attr || node.attr.id && footer_refs.includes(node.attr.id)) return JSON.stringify(node)
            if (node.attr.class) {
                let class_arr = node.attr.class.split(' ')
                if (class_arr.some(item => footer_refs.includes(item))) return JSON.stringify(node)
            }
        }
        if (node.child) {
            for (let i = 0; i < node.child.length; i++) {
                let r = this.get_footer(node.child[i])
                if (r) return r 
                else continue
            }
        }
        return ''
    },
    // async getScreenshot() {
    //   return html2canvas(document.querySelector('body'), {proxy: "https://lmjvwufhd8.execute-api.ap-southeast-1.amazonaws.com/latest"}).then(canvas => {
    //     let croppedCanvas = document.createElement('canvas')
    //     let croppedCanvasContext = croppedCanvas.getContext('2d')
    //     // console.log(croppedCanvas);
    //     let isMobile = window.innerWidth < window.innerHeight
    //     let innerHeight
    //     let innerWidth
    //     if (isMobile) {
    //       innerHeight = window.innerHeight
    //       innerWidth = window.innerWidth
    //     } else {
    //       innerHeight = window.innerHeight * 3
    //       innerWidth = window.innerWidth
    //       // innerWidth = window.innerWidth - ((window.innerHeight * 1.35) - window.innerHeight)
    //     }
    //     croppedCanvas.width = innerWidth;
    //     croppedCanvas.height = innerHeight;
    //     croppedCanvasContext.drawImage(canvas, 0, 0, innerWidth, innerHeight, 0, 0, innerWidth, innerHeight);
    //     return croppedCanvas.toDataURL()
    //   });
    // },
    save() {
      console.log("saving");
      let layout = (JSON.parse(JSON.stringify(this.$store.state.layout)))
      let process_remove_data_id = (tree_object) => {
        if (is_object(tree_object.attr)) {
            if ("data-id" in tree_object.attr) {
                delete tree_object.attr["data-id"];
            }
        }
        if (tree_object.child) {
          for (let item of tree_object.child) process_remove_data_id(item)
        }
      }
      process_remove_data_id(layout)

      if (process.env.NODE_ENV === "development") {
        console.log(layout);
        return
      }
      let data = {
        layout,
        origin: this.$store.state.origin
      }
      let page_path = this.$store.state.page_path;
      let params = {
        data, page_path,
      }
      if (this.$store.state.editing_mail) params.id = this.$store.state.editing_mail

      if (page_path) {
        axios.post(`/render_data_submit`, {
          jsonrpc: "2.0",
          method: "call",
          params
        }).then(() => {
            window.parent.document.getElementById("wrapper-save-button-close-loading")?.click()
            if (this.$store.state.header_footer_changed){ 
              this.$store.state.header_footer_changed = false
              parent.postMessage('header_footer_changed', '*')
            }
            this.$store.state.origin_header = this.get_header(this.$store.state.layout)
            this.$store.state.origin_footer = this.get_footer(this.$store.state.layout)
          })
        // this.getScreenshot()
        //     .then(res => {
        //       axios.post(`/render_data_submit`, {
        //         jsonrpc: "2.0",
        //         method: "call",
        //         params: {...params, image: res.split(",")[1]},
        //       })
        //           .then(res => {
        //             console.log(res);
        //             window.parent.document.getElementById("wrapper-save-button-close-loading").click()


        //             if (this.$store.state.header_footer_changed){ 
        //               this.$store.state.header_footer_changed = false
        //               parent.postMessage('header_footer_changed', '*')
        //             }
        //             this.$store.state.origin_header = this.get_header(this.$store.state.layout)
        //             this.$store.state.origin_footer = this.get_footer(this.$store.state.layout)
        //           })
        //     })
        //     .catch(e => {
        //       console.error(e)
        //       axios.post(`/render_data_submit`, {
        //         jsonrpc: "2.0",
        //         method: "call",
        //         params
        //       })
        //           .then(res => {
        //             console.log(res);
        //             window.parent.document.getElementById("wrapper-save-button-close-loading").click()
        //             if (this.$store.state.header_footer_changed){
        //               this.$store.state.header_footer_changed = false 
        //               parent.postMessage('header_footer_changed', '*')
        //             }
        //             this.$store.state.origin_header = this.get_header(this.$store.state.layout)
        //             this.$store.state.origin_footer = this.get_footer(this.$store.state.layout)
        //           })
        //     })
      }
    },

    createPopup() {
      if (this.activePopups.find((e) => e.name === this.popupName)) {
        this.$vs.notification({
          duration: 5000,
          color: "danger",
          progress: "auto",
          position: "bottom-center",
          title: "Tên đã tồn tại",
          text: `Mẹo: Đặt tên Popup theo tiền tố để dễ quản lý <br/> <small>VD: Popup-Contact, Popup-Form, Popup-Seller, ...</small>`,
        });
        return;
      }

      this.activePopups.push(
          Object.assign(
              {},
              {
                ...this.lodash.cloneDeep(this.popups[this.activePopup]),
                name: this.popupName,
              }
          )
      );
      this.statusConfirmName = false;
      this.active = "actived";
    },
  },
};
</script>

<style scope lang="scss">
.vs-button--default {
  i {
    color: white
  }
}

.vs-card__text {
  width: 100%;
}

::v-deep.input[type="number"] {
  width: 160px !important;
}

.deepsel-control-bar {
  font-family: "Montserrat";
  position: fixed;
  top: 1rem;
  right: 94%;
  z-index: 9999;
  display: flex;
  flex-direction: column;
}

.manager-dialog {
  padding-top: 50px;
  padding-bottom: 50px;

  .vs-dialog {
    min-width: 70vw;
    height: 100%;
    display: flex;
    flex-direction: column;

    .vs-dialog__content {
      flex-grow: 1;
      max-height: none;
    }

    .vs-dialog {
      min-width: 400px;
      height: auto;
      display: flex;
      flex-direction: column;

      .vs-dialog__content {
        flex-grow: inherit;
        max-height: inherit;
      }
    }
  }

  .manager-dialog-navbar {
    .vs-navbar {
      padding: 0;
      justify-content: start;

      .vs-navbar__item {
        padding-left: 0;
        padding-right: 0;
        margin-right: 2rem;
      }
    }
  }
}

.vs-dialog-content {
  .vs-input {
    width: 100%;
  }
}

.btn-circle {
  width: 50px;
  height: 50px;
  text-align: center;
  font-size: 20px;
  border-radius: 25px;
  box-shadow: 0 5px 19px rgba(6, 21, 40, 0.3);
  color: #e94057 !important;
  margin-bottom: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
