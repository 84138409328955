<template>
  <div v-show="isPopupStyle">
    <div class="position-fixed popup-drag" style="z-index: 999;border-radius: 8px;" :style="{top: old_mouse_y + 'px', left: old_mouse_x + 'px', width: WIDTH+'px', maxHeight: HEIGHT+'px'}">
      <div class="d-flex justify-content-center align-items-center" style="height: 30px">
        <button class="bg-white border-0 h-100">
          <i class="fas fa-grip-horizontal" @mousedown="mouse_down" style="cursor: move;font-size: 18px"></i>
        </button>
        <i @click="isPopupStyle = false" class="vs-icon-close vs-icon-hover-x position-absolute" style="right: 0; top: 0"></i>
      </div>
      <div class="px-2 pb-2 list-item" style="overflow: scroll;max-height: 470px;">
        <details>
          <summary>Border</summary>
          <div class="item align-items-start">
            <label for="">Type: </label>
            <div class="w-100" style="font-size: 13px;">
              <button class="w-100 mb-1 active" title="None" @click="borderStyle = ''" style="border: none;" :style="{color: borderStyle === 0 ? 'var(--blue)' : '' }">None</button>
              <button class="border w-100 mb-1" title="Solid" @click="borderStyle = 'solid'" :style="{color: borderStyle === 'solid !important' ? 'var(--blue)' : '' }">Solid</button>
              <button class="border w-100 mb-1 " title="Dotted" @click="borderStyle = 'dotted'" :style="{color: borderStyle === 'dotted !important' ? 'var(--blue)' : '' }" style="border-style:dotted !important">Dotted</button>
              <button class="border w-100 mb-1" title="Dashed" @click="borderStyle = 'dashed'" :style="{color: borderStyle === 'dashed !important' ? 'var(--blue)' : '' }" style="border-style:dashed !important">Dashed</button>
            </div>
          </div>

          <div v-if="borderStyle && borderColor">
            <div class="item">
              <label for="">Border color: </label>
              <ColourPicker
                  class="color-pick"
                  v-model="borderColor"
                  :color="borderColor"
                  picker="compact"
              />
            </div>
            <div class="item">
              <label for="">Size (px): </label>
              <vs-input type="number" v-model="borderWidth"/>
            </div>
            <div class="item" v-show="borderRadius !== false">
              <label for="">Round Corners (px): </label>
              <vs-input type="number" min="0" v-model="borderRadius"/>
            </div>
            <div class="item justify-content-end" v-show="borderRadius === false">
              <vs-button class="m-0" size="mini" @click="borderRadius = borderRadius1">Round Corner All</vs-button>
            </div>
            <div class="item">
              <label for="">Top Left (px): </label>
              <vs-input type="number" min="0" v-model="borderRadius1" />
            </div>
            <div class="item">
              <label for="">Top Right (px): </label>
              <vs-input type="number" min="0" v-model="borderRadius2"/>
            </div>
            <div class="item">
              <label for="">Bottom Right (px): </label>
              <vs-input type="number" min="0" v-model="borderRadius3"/>
            </div>
            <div class="item">
              <label for="">Bottom Left (px): </label>
              <vs-input type="number" min="0" v-model="borderRadius4"/>
            </div>
          </div>
        </details>
        <details>
          <summary>Color</summary>
          <div class="item">
            <label for="">Background Color : </label>
            <ColourPicker
              class="color-pick"
              v-model="background_color"
              :color="background_color"
              picker="compact"
            />
          </div>
          <div class="item">
            <label for="">Text Color: </label>
            <ColourPicker
              class="color-pick"
              v-model="color"
              :color="color"
              picker="compact"
            />
          </div>
          <div class="item">
            <label for="">Opacity (%): </label>
            <vs-input type="number" min="0" v-model="opacity"/>
          </div>
        </details>
        <details>
          <summary>Box Shadow</summary>
          <!-- <div class="item justify-content-end">
            <vs-button class="m-0" size="mini" warn gradient @click="editing.component_data.attr.style.boxShadow = null">Đặt lại</vs-button>
          </div> -->
          <div class="item">
            <label for="">Horizontal (px): </label>
            <vs-input type="number" v-model="boxShadowOffsetX"/>
            <!-- <input type="range" name="range" min="0" max="100" step="1" v-model="" onchange="changeValue(event)"/> -->
            <!-- <div class="range"><output id="output" name="result"></output></div> -->
          </div>
          <div class="item">
            <label for="">Vertical (px): </label>
            <vs-input type="number" v-model="boxShadowOffsetY"/>
          </div>
          <div class="item">
            <label for="">Shadow Color: </label>
            <ColourPicker
                class="color-pick"
                v-model="boxShadowColor"
                :color="boxShadowColor"
                picker="compact"
            />
          </div>
          <div class="item">
            <label for="">Shadow Blur (px): </label>
            <vs-input type="number" v-model="boxShadowBlur"/>
          </div>
          <div class="item">
            <label for="">Shadow Spread (px): </label>
            <vs-input type="number" v-model="boxShadowSpread"/>
          </div>
          <div class="item align-items-start pt-2">
            <label for="">Type: </label>
            <div class="custom-select-vs w-100">
              <select v-model="boxShadowInset">
                <option value="outline">Outset</option>
                <option value="inset">Inset</option>
              </select>
            </div>
          </div>
        </details>
      </div>
    </div>
  </div>
</template>

<script>
import ColourPicker from "@/builder_components/ColourPicker";

function boxShadowToObject(box) {
  let obj = {
    inset: 'outline',
    offsetX: 0,
    offsetY: 0,
    blurRadius: 0,
    spreadRadius: 0,
    color: '',
  }
  if (!box) return obj

  let arr_box = box.split(' ')
  switch (arr_box.length) {
    case 3:
      obj.offsetX = parseInt(arr_box[0])
      obj.offsetY = parseInt(arr_box[1])
      obj.color = arr_box[2]
      break;
    case 4:
      if (arr_box[0] === 'inset') {
        obj.offsetX = parseInt(arr_box[1])
        obj.offsetY = parseInt(arr_box[2])
        obj.inset = 'inset'
        obj.color = arr_box[3]
      } else {
        obj.offsetX = parseInt(arr_box[0])
        obj.offsetY = parseInt(arr_box[1])
        obj.blurRadius = parseInt(arr_box[2])
        obj.color = arr_box[3]
      }
      break;
    case 5:
      obj.offsetX = parseInt(arr_box[0])
      obj.offsetY = parseInt(arr_box[1])
      obj.blurRadius = parseInt(arr_box[2])
      obj.spreadRadius = parseInt(arr_box[3])
      obj.color = arr_box[4]
      break;
    case 6:
      obj.inset = 'inset'
      obj.offsetX = parseInt(arr_box[1])
      obj.offsetY = parseInt(arr_box[2])
      obj.blurRadius = parseInt(arr_box[3])
      obj.spreadRadius = parseInt(arr_box[4])
      obj.color = arr_box[5]
      break;
    default:
      break;
  }
  return obj
}
function ToBoxshawdow (object) {
  let box_shadow =
    (object.inset==='inset' ? 'inset ' : '') +
    (object.offsetX+'px ') +
    (object.offsetY+'px ') +
    (object.blurRadius+'px ') +
    (object.spreadRadius+'px ') +
    (object.color ? object.color : '')
  // console.log(box_shadow);
  return box_shadow
}
const WIDTH = 300
const HEIGHT = 500

// import ClickOutside from 'vue-click-outside'

export default {
  // directives: {
  //   ClickOutside
  // },
  components: {
    ColourPicker
  },
  data() {
    return {
      opened: false,
      old_mouse_x: 0,
      old_mouse_y: 100,
    }
  },
  created(){
    this.old_mouse_x = (window.innerWidth - WIDTH)/2
  },
  computed: {
    color: {
      get() {
        if (this.editing && this.editing.component_data.attr && this.editing.component_data.attr.style) {
          return this.editing.component_data.attr.style.color;
        } else return null;
      },
      set(value) {
        if (this.editing && this.editing.component_data.attr && this.editing.component_data.attr.style) {
          this.editing.component_data.attr.style.color = value;
        }
      },
    },
    isPopupStyle: {
      get() {
        return this.$store.state.isPopupStyle
      },
      set(val) {
        this.$store.state.isPopupStyle = val
      }
    },
    WIDTH() { return WIDTH },
    HEIGHT() { return HEIGHT },
    boxShadow: {
      get() {
        if (this.editing && this.editing.component_data.attr && this.editing.component_data.attr.style && this.editing.component_data.attr.style.boxShadow) {
          return boxShadowToObject(this.editing.component_data.attr.style.boxShadow)
        } else return boxShadowToObject();
      },
      set(val) {
        this.editing.component_data.attr.style.boxShadow = ToBoxshawdow(val)
      }
    },
    boxShadowColor: {
      get(){
        if (this.boxShadow) return this.boxShadow.color
        else return ''
      },
      set(val){
        this.boxShadow = {...this.boxShadow,...{color: val}}
      }
    },
    boxShadowInset: {
      get(){
        if (this.boxShadow) return this.boxShadow.inset
        else return ''
      },
      set(val){
        this.boxShadow = {...this.boxShadow,...{inset: val}}
      }
    },
    boxShadowOffsetX: {
      get(){
        if (this.boxShadow) return this.boxShadow.offsetX
        else return 0
      },
      set(val){
        this.boxShadow = {...this.boxShadow,...{offsetX: val}}
      }
    },
    boxShadowOffsetY: {
      get(){
        if (this.boxShadow) return this.boxShadow.offsetY
        else return 0
      },
      set(val){
        this.boxShadow = {...this.boxShadow,...{offsetY: val}}
      }
    },
    boxShadowBlur: {
      get(){
        if (this.boxShadow) return this.boxShadow.blurRadius
        else return 0
      },
      set(val){
        this.boxShadow = {...this.boxShadow,...{blurRadius: val}}
      }
    },
    boxShadowSpread: {
      get(){
        if (this.boxShadow) return this.boxShadow.spreadRadius
        else return 0
      },
      set(val){
        this.boxShadow = {...this.boxShadow,...{spreadRadius: val}}
      }
    },
    opacity: {
      get() {
        if (this.editing && this.editing.component_data.attr && this.editing.component_data.attr.style && this.editing.component_data.attr.style.opacity) {
          return parseInt(this.editing.component_data.attr.style.opacity);
        } else return 100;
      },
      set(value) {
        if (this.editing && this.editing.component_data.attr && this.editing.component_data.attr.style) {
          this.editing.component_data.attr.style.opacity = value + '%';
        }
      },
    },
    background_color: {
      get() {
        if (this.editing && this.editing.component_data.attr && this.editing.component_data.attr.style) {
          return this.editing.component_data.attr.style.backgroundColor;
        } else return null;
      },
      set(value) {
        if (this.editing && this.editing.component_data.attr && this.editing.component_data.attr.style) {
          this.editing.component_data.attr.style.backgroundColor = value;
        }
      },
    },
    editing() {
      return this.$store.state.editing;
    },
    borderStyle: {
      get() {
        if (this.editing && this.editing.component_data.attr && this.editing.component_data.attr.style && this.editing.component_data.attr.style.borderStyle) {
          return this.editing.component_data.attr.style.borderStyle;
        } else return 0;
      },
      set(val) {
        this.editing.component_data.attr.style.borderStyle = val==0 ? null : (val + ' !important')
        if (!parseInt(this.editing.component_data.attr.style.borderWidth)) this.editing.component_data.attr.style.borderWidth = '1px !important'
        if (!this.editing.component_data.attr.style.borderColor) this.editing.component_data.attr.style.borderColor = '#00000'

      }
    },
    borderWidth: {
      get() {
        if (this.editing && this.editing.component_data.attr && this.editing.component_data.attr.style) {
          return parseInt(this.editing.component_data.attr.style.borderWidth)
        } else return 0;
      },
      set(val) {
        this.editing.component_data.attr.style.borderWidth = val + 'px' + ' !important'
      }
    },
    borderColor: {
      get() {
        if (this.editing && this.editing.component_data.attr && this.editing.component_data.attr.style) {
          return this.editing.component_data.attr.style.borderColor;
        } else return false;
      },
      set(val) {
        this.editing.component_data.attr.style.borderColor = val + ' !important'
      }
    },    
    borderRadius: {
      get() {
        if (this.editing && this.editing.component_data.attr && this.editing.component_data.attr.style && this.editing.component_data.attr.style.borderRadius) {
          let arr_radius = this.editing.component_data.attr.style.borderRadius ? this.editing.component_data.attr.style.borderRadius.split(" ").map(e => parseInt(e)) : [0,0,0,0]
          if (arr_radius[0]*4 !== (arr_radius[0]+arr_radius[1]+arr_radius[2]+arr_radius[3])) {
            return false
          }
          return arr_radius[0]
        } else return 0;
      },
      set(val) {
        let arr_radius = [val,val,val,val]
        this.editing.component_data.attr.style.borderRadius = arr_radius.join('px ') + 'px'
      }
    },
    borderRadius1: {
      get() {
        if (this.editing && this.editing.component_data.attr && this.editing.component_data.attr.style && this.editing.component_data.attr.style.borderRadius) {
          let arr_radius = this.editing.component_data.attr.style.borderRadius ? this.editing.component_data.attr.style.borderRadius.split(" ").map(e => parseInt(e)) : [0,0,0,0]
          return arr_radius[0]
        } else return 0;
      },
      set(val) {
        let arr_radius = this.editing.component_data.attr.style.borderRadius ? this.editing.component_data.attr.style.borderRadius.split(" ").map(e => parseInt(e)) : [0,0,0,0]
        arr_radius[0] = val
        this.editing.component_data.attr.style.borderRadius = arr_radius.join('px ') + 'px'
      }
    },
    borderRadius2: {
      get() {
        if (this.editing && this.editing.component_data.attr && this.editing.component_data.attr.style && this.editing.component_data.attr.style.borderRadius) {
          let arr_radius = this.editing.component_data.attr.style.borderRadius ? this.editing.component_data.attr.style.borderRadius.split(" ").map(e => parseInt(e)) : [0,0,0,0]
          return arr_radius[1]
        } else return 0;
      },
      set(val) {
        let arr_radius = this.editing.component_data.attr.style.borderRadius ? this.editing.component_data.attr.style.borderRadius.split(" ").map(e => parseInt(e)) : [0,0,0,0]
        arr_radius[1] = val
        this.editing.component_data.attr.style.borderRadius = arr_radius.join('px ') + 'px'
      }
    },
    borderRadius3: {
      get() {
        if (this.editing && this.editing.component_data.attr && this.editing.component_data.attr.style && this.editing.component_data.attr.style.borderRadius) {
          let arr_radius = this.editing.component_data.attr.style.borderRadius ? this.editing.component_data.attr.style.borderRadius.split(" ").map(e => parseInt(e)) : [0,0,0,0]
          return arr_radius[2]
        } else return 0;
      },
      set(val) {
        let arr_radius = this.editing.component_data.attr.style.borderRadius ? this.editing.component_data.attr.style.borderRadius.split(" ").map(e => parseInt(e)) : [0,0,0,0]
        arr_radius[2] = val
        this.editing.component_data.attr.style.borderRadius = arr_radius.join('px ') + 'px'
      }
    },
    borderRadius4: {
      get() {
        if (this.editing && this.editing.component_data.attr && this.editing.component_data.attr.style && this.editing.component_data.attr.style.borderRadius) {
          let arr_radius = this.editing.component_data.attr.style.borderRadius ? this.editing.component_data.attr.style.borderRadius.split(" ").map(e => parseInt(e)) : [0,0,0,0]
          return arr_radius[3]
        } else return 0;
      },
      set(val) {
        let arr_radius = this.editing.component_data.attr.style.borderRadius ? this.editing.component_data.attr.style.borderRadius.split(" ").map(e => parseInt(e)) : [0,0,0,0]
        arr_radius[3] = val
        this.editing.component_data.attr.style.borderRadius = arr_radius.join('px ') + 'px'
      }
    },
  },
  methods: {
    mouse_down(e) {
      e.preventDefault()
      e.stopPropagation()
      document.addEventListener("mousemove", this.mouse_move);
      document.addEventListener("mouseup", this.mouse_up);
    },
    //drag adapter
    mouse_move(e){
      if (e.clientX > WIDTH/2 && e.clientX < window.innerWidth-WIDTH/2  && e.clientY > 0) {
        this.old_mouse_x = e.clientX-WIDTH/2
        this.old_mouse_y = e.clientY
      }
    },
    //drag adapter
    mouse_up() {
      document.removeEventListener("mousemove", this.mouse_move);
      document.removeEventListener("mouseup", this.mouse_up);

    },
    clickOut() {
      if (this.isPopupStyle) {
        this.isPopupStyle = false
      }
    }
  },
  mounted() {
    window.addEventListener("resize", () => {
      if (window.innerWidth <= 1024) {
        this.old_mouse_x = (window.innerWidth - WIDTH)/2
        this.old_mouse_y = 100
      } else {
        this.old_mouse_x = (window.innerWidth - WIDTH)/2
      }
    });
  }
}
</script>

<style scoped lang="scss">
::v-deep input,.vs-select__input {
  font-size: 12px !important;
  padding: 4px 7px;
}
::v-deep .vs-select__input {
  min-height: 30px
}
::v-deep details {
  margin-bottom: .25rem;
  .vs-switch{
    height: 22px;
    min-width: 40px;
    .vs-switch__circle {
      width: 18px;
      height: 18px;
    }
  }
  summary {
    width: 100%;
    font-size: 12px;
    padding: 2px 4px;
    background: #F5F5F5;
    color: black;
    cursor: pointer;
    border-radius: 5px;
    &:focus {
      outline: none
    }
  }
  label {
    padding: 0;
    min-width: 120px;
    font-size: 12px;
  }
  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .25rem 0;
  }

}
i {
  color: rgba(var(--vs-color), 1)
}
.box-shadow {
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 1);
  &:focus {
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 1) !important;
  }
}
.box-shadow-inset {
  box-shadow: inset 0px 0px 4px 2px rgba(0, 0, 0, 1);
  &:focus {
    box-shadow: inset 0px 0px 4px 2px rgba(0, 0, 0, 1) !important;
  }
}
.popup-drag {
  box-shadow: 0 5px 19px 6px rgba(6, 21, 40, 0.3);
  background-color: white;
  .list-item {
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
.custom-select-vs {
  background: rgba(var(--vs-gray-2), 1);
  padding: 6px 10px;
  border: none;
  border-radius: 12px;
  select {
    color: rgba(var(--vs-text), 1);
    width: 100%;
    border: none;
    background: rgba(var(--vs-gray-2), 1);
    font-size: 12px !important;
    &:focus {
      outline: none;
    }
  }
}
</style>