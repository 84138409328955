<template>
    <vs-dialog
      not-center
      width="600px"
      class="menu-dialog"
      overflow-hidden
      v-model="$store.state.settingMenuDialog"
      @close="editing.save()"
    >
      <template #header>
        <h5 class="mt-2 text-center">
          Setting <b>Menu</b>
        </h5>
      </template>
      <div v-if="editing && editing.items.length === 0" class="text-center">
        No menu item found.
      </div>
      <Tree v-if="editing" :value="editing.items" class="menu-tree">
        <div class="position-relative d-flex justify-content-between align-items-center cursor-pointer" slot-scope="{node,path}" :title="node.text">
          <div>
            <i class="mx-2" :class="node.icon"></i> {{ node.text }}
          </div>
          <div class="position-relative action-menu">
            <vs-button circle shadow class="">
              <i class="fas fa-ellipsis-h"></i>
            </vs-button>
            <div class="list position-absolute">
              <div class="action"
                    @click="editMenuDialog = true;activeMenu = JSON.parse(JSON.stringify(node));pathActiveMenu = path">
                <i class="fas fa-pen-alt"></i> Edit
              </div>
              <!-- <div class="action" @click="activeMenu = JSON.parse(JSON.stringify(node));changeMenu('create')">
                <i class="fas fa-clone"></i> Duplicate
              </div> -->
              <div class="action" @click="removeMenuDialog = true;pathActiveMenu = path">
                <i class="fas fa-trash"></i> Delete
              </div>
            </div>
          </div>
        </div>
      </Tree>
      <template #footer>
        <div class="footer-dialog mt-3">
          <vs-button class="px-3" circle gradient @click="createMenuDialog = true,activeMenu = {text: 'Item', parent: '', link: '', children: [], icon: '0'}">
            <i class="fas fa-plus mr-2 text-white"></i> Add item
          </vs-button>
        </div>
      </template>
      <vs-dialog v-model="editMenuDialog">
        <template #header>
          <h5 class="not-margin mt-3 font-weight-normal">
            Edit menu <b>{{ activeMenu.text }}</b>
          </h5>
        </template>
        <div>
          <vs-input v-model="activeMenu.text" label="Text" placeholder="Text"></vs-input>
        </div>
        <div class="mt-4">
          <vs-input
              class="mt-1"
              v-model="activeMenu.link"
              type="url"
              placeholder="Paste any link here..."
              label="Link"
          />
          <vs-select  class="mw-100 mt-1" filter v-model="linkIndex" placeholder="Or a link in your website">
            <vs-option
                v-for="(link, i) in links"
                :key="i"
                :label="link.name"
                :value="i + 1"
            >
              {{ link.name }}
            </vs-option>
          </vs-select>
          
        </div>
        <template #footer>
          <div class="footer-dialog">
            <div class="footer-dialog d-flex justify-content-center">
              <vs-button @click="changeMenu('edit')" class="px-3">
                Save
              </vs-button>
              <vs-button @click="editMenuDialog=false" dark border class="px-3">
                Cancel
              </vs-button>
            </div>
          </div>
        </template>
      </vs-dialog>
      <vs-dialog v-model="removeMenuDialog">
        <template #header>
          <h5 class="not-margin mt-3 font-weight-normal">
            Remove menu <b>{{ activeMenu.text }}</b>
          </h5>
        </template>
        <lottie :options="defaultOptions" :width="100" :height="100"/>
        <template #footer>
          <div class="footer-dialog d-flex justify-content-center">
            <vs-button @click="changeMenu('delete')" class="px-3">
              Confirm
            </vs-button>
            <vs-button @click="removeMenuDialog=false" dark border class="px-3">
              Cancel
            </vs-button>
          </div>
        </template>
      </vs-dialog>
      <vs-dialog v-model="createMenuDialog">
        <template #header>
          <h5 class="not-margin font-weight-normal">
            Create menu
          </h5>
        </template>
        <div v-if="editing">
          <vs-input v-model="activeMenu.text" label="Text" placeholder="Text"></vs-input>
          <vs-input
            label="Link" 
            class="mt-4"
            v-model="activeMenu.link"
            type="url"
            placeholder="Or paste any link here..."
          />
          <vs-select class="mw-100 mt-1" filter v-model="linkIndex" placeholder="Select a link in your website">
            <vs-option
                v-for="(link, i) in links"
                :key="i"
                :label="link.name"
                :value="i + 1"
            >
              {{ link.name }}
            </vs-option>
          </vs-select>
        </div>
        <template #footer>
          <div class="footer-dialog d-flex justify-content-center">
            <vs-button @click="changeMenu('create')" class="px-3">
              Create
            </vs-button>
            <vs-button @click="createMenuDialog=false" dark border class="px-3">
              Cancel
            </vs-button>
          </div>
        </template>
  
  
      </vs-dialog>
    </vs-dialog>
  </template>
  
  <script>
  import {
    Tree, Draggable, // plugins Draggable
    // foldAll, unfoldAll, cloneTreeData, walkTreeData, getPureTreeData, // utils
  } from 'he-tree-vue'
  import 'he-tree-vue/dist/he-tree-vue.css' // base style
  import * as animationData from '@/assets/animation/delete.json';
  import Lottie from 'vue-lottie'
  
  export default {
    components: {
      // TreeNotDraggable: Tree,
      Tree: Tree.mixPlugins([Draggable]),
      Lottie,
    },
    data() {
      return {
        defaultOptions: {animationData: animationData.default},
        activeMenu: '',
        createMenuDialog: false,
        removeMenuDialog: false,
        editMenuDialog: false,
        pathActiveMenu: ''
      }
    },
    computed: {
      editing() {
        return this.$store.state.editing;
      },
      linkIndex: {
        get() {
          return this.links.findIndex(e => e.path === this.activeMenu.link) + 1
        },
        set(val) {
          this.activeMenu.link = this.links[val - 1].path
        }
      },
      links() {
        try {
          let res = []
          let common_data = this.$store.state.common_data
  
          let pages = common_data.pages.map(page => {
            if (page.path === 'home') return {
              name: `Page: ${page.name}`,
              path: '/'
            }
            return {
              name: `Page: ${page.name}`,
              path: '/' + page.path
            }
          })
          let categories = common_data.categories_id ? Object.keys(common_data.categories_id).map(key => {
            return {
              name: `Category: ${common_data.categories_id[key].name}`,
              path: `/shop?category_id=${key}`,
              is_deepsel_link: this.is_shop_page
            }
          }) : []
          let tags = common_data.product_tags ? common_data.product_tags.map(tag => {
            return {
              name: `Tag: ${tag.name}`,
              path: `/shop?tag_id=${tag.id}`,
              is_deepsel_link: this.is_shop_page
            }
          }) : []
          let collections = common_data.collections ? common_data.collections.map(collection => {
            return {
              name: `Collection: ${collection.name}`,
              path: `/shop?collection=${collection.string_id}`,
              is_deepsel_link: this.is_shop_page
            }
          }) : []
          let brands = common_data.brands ? Object.keys(common_data.brands).map(key => {
            return {
              name: `Brand: ${common_data.brands[key].name}`,
              path: `/shop?brand_id=${key}`,
              is_deepsel_link: this.is_shop_page
            }
          }) : []
  
          let blog_categories = Object.keys(common_data.blog_categories).map(key => {
            return {
              name: `Blog category: ${common_data.blog_categories[key]}`,
              path: `/blogs?category=${key}`
            }
          })
          let blog_tags = Object.keys(common_data.blog_tags).map(key => {
            return {
              name: `Tag blog: ${common_data.blog_tags[key]}`,
              path: `/blogs?tag=${key}`
            }
          })
          // let blog_authors = Object.keys(common_data.blog_authors).map(key => {
          //   return {
          //     name: `Author blog: ${common_data.blog_authors[key]}`,
          //     path: `/blogs#author=${common_data.blog_authors[key]}`
          //   }
          // })
          let sort_filters = []
          if (this.is_shop_page) {
            sort_filters = [
              {
                name: 'Price: Low to High',
                value: '/sort=price_asc',
              },
              {
                name: 'Price: High to Low',
                value: '/sort=price_desc',
              },
              {
                name: 'Best selling',
                value: '/sort=selling',
              },
              {
                name: 'New',
                value: '/sort=new',
              },
              {
                name: 'Name A-Z',
                value: '/sort=name_asc',
              },
              {
                name: 'Name Z-A',
                value: '/sort=name_desc',
              }
            ]
          }
  
          res = res.concat(pages)
              .concat([
                {
                  name: 'Page: Shop Cart',
                  path: '/shop/cart'
                },
                {
                  name: 'Page: Wishlist',
                  path: '/shop?wishlist=true',
                  is_deepsel_link: this.is_shop_page
                },
              ])
              .concat(categories)
              .concat(tags)
              .concat(collections)
              .concat(sort_filters)
              .concat(brands)
              .concat(blog_categories)
              .concat(blog_tags)
              .concat([
                {
                  name: 'Tel',
                  path: 'tel: phone_number'
                },
                {
                  name: 'Mail to',
                  path: 'mailto:mail'
                },
              ])
          // .concat(blog_authors)
  
          return res
  
        } catch (error) {
          console.error("Error", error)
          console.log("links > computed > QuickEditor");
          return ''
        }
      },
    },
    methods: {
      changeMenu(method) {
        if (method === 'create') {
          this.createMenuDialog = false
          this.editing.addItem(this.activeMenu)
        } else if (method === 'edit') {
          this.editMenuDialog = false
          this.editing.editItem(this.activeMenu, this.pathActiveMenu)
        } else if (method === 'delete') {
          this.removeMenuDialog = false
          this.editing.deleteItem(this.pathActiveMenu)
        }
      },
    }
  }
  </script>
  
  <style scoped lang="scss">
  ::v-deep.box-select-icon{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .vs-select__option {
      width: 48px;
      justify-content: center;
    }
    .vs-select__option.hiddenOption {
      display: none;
    }
    h5 {
      width: 100%;
    }
    .vs-select__option:hover:not(:disabled) {
      padding-left: 10px;
    }
  }
  ::v-deep {
    .vs-input,.vs-select__input {
      font-size: 12px;
      width: 100%;
    }
  }
  ::v-deep.action-menu {
    &:hover {
      .list {
        visibility: visible;
        right: 100%;
        opacity: 1;
      }
    }
  
    .list {
      z-index: 99999;
      width: 180px;
      border-radius: 8px;
      padding: .5rem .5rem;
      right: 110%;
      opacity: 0;
      top: 0;
      background-color: white;
      visibility: hidden;
      box-shadow: 0 5px 19px rgba(6, 21, 40, .3);
      transition: .2s;
  
      .action {
        border-radius: 8px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: .5rem;
        cursor: pointer;
  
        i {
          padding: 0 1rem 0 .5rem
        }
  
        &:hover {
          background-color: var(--deepsel-bg-light);
        }
      }
  
      .action.disable {
        background-color: var(--deepsel-bg-light);
        pointer-events: none;
        opacity: 0.35;
      }
    }
  }
  
  ::v-deep.menu-dialog {
    padding: 0;
  
    .vs-dialog {
      background-color: var(--deepsel-bg-light);
    }
  
    .vs-dialog--scroll .vs-dialog__content {
      max-height: calc(80vh - 50px);
    }
  
    .menu-tree {
      height: 70vh;
      overflow-y: scroll;
  
      .tree-node {
        border-radius: 8px;
        border-left: 1px dashed;
        background-color: white;
        position: relative;
        transition: .2s;
  
        &:hover {
          background-color: rgb(var(--deepsel-bg-light));
        }
      }
  
      // .tree-children {
      //   position: relative;
      //   &:not(:first-child) {
      //     &::after {
      //       content: "";
      //       position: absolute;
      //       top: 0;
      //       height: calc(100% - 43px);;
      //       width: 5px;
      //       background-color: white;
      //     }
      //   }
      // }
    }
  }
  </style>